import Progress from "./Progress";
import ChangesObserver from "./ChangesObserver";
import { useRecoilValue } from "recoil";
import quizStore from "../store";
import useQuizChannel from "../../../../hooks/events/useQuizChannel";

const QuizObserver = () => {
	useQuizChannel();

	const options = useRecoilValue(quizStore);

	return (
		<div className="relative">
			<div className="bg-white p-5 fixed w-full">
				<Progress
					percent={Math.trunc(Number.parseInt(options.overall_progress)) || 0}
				/>
			</div>
			<div className="flex flex-col gap-[10px] px-5 pb-5 mt-[135px] h-[calc(100vh-106px-134px-20px)] overflow-y-auto">
				{options.data.map((q, i) => (
					<ChangesObserver
						key={i}
						description={q.description}
						index={i}
						title={q.text}
						total={options.data.length}
						results={q.results}
					/>
				))}
			</div>
		</div>
	);
};

export default QuizObserver;
