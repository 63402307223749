import { useRecoilValue } from "recoil";
import Chip from "../../shared/Chip";
import buzzerStore from "../../pages/activities/buzzer/store/buzzerStore";

const Counter = () => {
	const buzzerQue = useRecoilValue(buzzerStore);

	if (buzzerQue.currentIndex + 1 > buzzerQue.questions.length) {
		return <></>
	}

	return (
		<Chip className="flex items-center gap-4 self-stretch p-2">
			<Chip className="flex items-center self-stretch bg-dark-extra px-9 text-lg font-bold text-dolphin-700">
				{buzzerQue.currentIndex + 1}/{buzzerQue.questions.length}
			</Chip>
		</Chip>
	);
};

export default Counter;
