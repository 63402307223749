import { useTranslation } from "react-i18next";
import Chip from "../../../../shared/Chip";
import Circle from "../../../../shared/Circle";

type Props = {
	index: number;
	total: number;
	title: string;
	description: string;
	results: {
		true: number;
		false: number;
		true_count: number;
		false_count: number;
	};
};

const ChangesObserver = ({
	description,
	index,
	title,
	total,
	results,
}: Props) => {
	const active = results.false > 0 || results.true > 0;
	const { t } = useTranslation();

	return (
		<Chip className="!p-4 bg-violet-100 flex gap-5 rounded transition-all duration-300">
			<div className="flex flex-col gap-[6px] flex-1 min-h-[120px]">
				<div className="flex flex-col gap-[10px]">
					<Chip className="bg-white text-dark-mid text-sm leading-4 px-4 py-[6px] w-fit">
						{index + 1}/{total}
					</Chip>
					<h2 className="text-2xl font-bold leading-7 text-dolphin-700">
						{title}
					</h2>
				</div>
				<p
					className="text-dolphin-700 text-sm leading-4"
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			</div>
			<div className="flex items-center self-stretch gap-5">
				<Chip
					className={`rounded !p-4 ${
						active ? "bg-violet-600" : "bg-violet-400"
					} flex flex-col justify-between w-[190px] self-stretch`}
				>
					<div className="flex justify-between items-start">
						<p className="text-white">{t("True")}</p>
						<Circle
							className={`w-9 h-9 bg-white ${
								active ? "text-violet-600" : "text-violet-400"
							} flex items-center justify-center font-bold`}
						>
							{active ? results.true_count : "-"}
						</Circle>
					</div>
					<div>
						<Chip className="bg-violet-100 px-[6xp] py-[3px] flex items-center gap-[10px]">
							<div className="rounded w-[78px] bg-white flex-1 h-[7px] overflow-hidden transition-all duration-300">
								<span
									style={{ width: active ? results.true : 0 }}
									className="h-[7px] bg-violet-600 w-[20px] block transition-all duration-200"
								/>
							</div>
							<p className="text-[11px] leading-[14px] text-dark-mid w-7 text-right">
								{active ? `${Math.trunc(results.true)}%` : "0%"}
							</p>
						</Chip>
					</div>
				</Chip>
				<Chip
					className={`rounded !p-4 ${
						active ? "bg-orange-600" : "bg-orange-400"
					} flex flex-col justify-between w-[190px] self-stretch`}
				>
					<div className="flex justify-between items-start">
						<p className="text-white">{t("False")}</p>
						<Circle
							className={`w-9 h-9 bg-white  ${
								active ? "text-orange-600" : "text-orange-400"
							} flex items-center justify-center font-bold`}
						>
							{active ? results.false_count : "-"}
						</Circle>
					</div>
					<div>
						<Chip className="bg-orange-100 px-[6xp] py-[3px] flex items-center gap-[10px]">
							<div className="rounded w-[78px] bg-white flex-1 h-[7px] overflow-hidden transition-all duration-300">
								<span
									style={{ width: active ? results.false : 0 }}
									className="h-[7px] bg-orange-600 w-[20px] block transition-all duration-200"
								/>
							</div>
							<p className="text-[11px] leading-[14px] text-dark-mid w-7 text-right">
								{active ? `${Math.trunc(results.false)}%` : "0%"}
							</p>
						</Chip>
					</div>
				</Chip>
			</div>
		</Chip>
	);
};

export default ChangesObserver;
