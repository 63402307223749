import axios from "../clients/axiosInstance";
import END_POINT from "../api/END_POINTS";
type ActionStates =
	| "prestart"
	| "start"
	| "show"
	| "end"
	| "show_results"
	| "next"
	| "show_viewer"
	| "show_viewer_list_options"
	| "show_viewer_users_finished"
	| "show_all"
	| "show_all_score"
	| "show_all_scale"
	| "prev";

const { V1 } = END_POINT;

export const getEvents = () => {
	return axios.get(V1.GET_EVENTS);
};

export const goOnline = () => {
	return axios.post(V1.GO_ONLINE);
};

export const goOffline = () => {
	return axios.post(V1.GO_OFFLINE);
};

export const goBack = () => {
	return axios.get(V1.GO_BACK);
};

export const contentStandby = (id: number) => {
	return axios.post(END_POINT.V1.CONTENT_STANDBY(id));
};

export const startContentSharing = (
	id: number,
	action: ActionStates,
	sub_id?: number
) => {
	let data: { action: ActionStates; sub_id?: number } = {
		action,
	};

	if (sub_id) {
		data["sub_id"] = sub_id;
	}

	return axios.post(END_POINT.V1.PRESENT_CONTENT(id), data);
};

export const deleteResults = (id: string | number) => {
	return axios.get(END_POINT.V1.RESET_RESULTS(id));
};
