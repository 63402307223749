import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import userLiveStore, { LiveUsers } from "../../../../store/userLiveStore";
import UserChip from "./UserChip";
import buzzerStore from "../store/buzzerStore";
import { selectUser, stopUser } from "../../../../lib/services/buzzer";
import eventState from "../../../../store/eventState";
import userSelection from "./store";
import { Button, useToast } from "@chakra-ui/react";
import SmallRocket from "../../../../icons/SmallRocket";
import Clock from "../../../../icons/Clock";
import { useTranslation } from "react-i18next";
import { startContentSharing } from "../../../../lib/services/events";
import { useNavigate } from "react-router-dom";

const UsersObserver = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	const [userLive] = useRecoilState(userLiveStore);
	const eventStorage = useRecoilValue(eventState);

	const [userSelected, setUserSelected] = useRecoilState(userSelection);
	const [buzzerStorage, setBuzzerStorage] = useRecoilState(buzzerStore);

	const [tempUser, setTempUser] = useState<LiveUsers | undefined>(undefined);

	const handleOpenUser = (user: LiveUsers) => {
		setTempUser(user);
		setUserSelected((prev) => ({
			...prev,
			user: undefined,
			adminSelecting: true,
			userSelecting: true,
		}));
	};

	const handleUserSelection = () => {
		if (!loading) {
			setLoading(true);
			selectUser(
				eventStorage.activities[eventStorage.activeContentIndex].id,
				+buzzerStorage.questions[buzzerStorage.currentIndex].id,
				+(tempUser as LiveUsers).user_id
			)
				.then((data) => {
					setUserSelected({
						user: tempUser,
						userSelecting: true,
						selectionState: false,
						adminSelecting: false,
						userSelected: -1,
					});
				})
				.catch(() => {
					toast({
						title: t("Something went wrong") + ".",
						description: "",
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const tryAgain = () => {
		setTempUser(undefined);
		setUserSelected({
			user: undefined,
			userSelecting: true,
			selectionState: false,
			adminSelecting: true,
			userSelected: -1,
		});
	};

	const nextQuestion = () => {
		setLoading(true);
		if (buzzerStorage.currentIndex + 1 === buzzerStorage.questions.length) {
			startContentSharing(
				eventStorage.activities[eventStorage.activeContentIndex].id,
				"end"
			)
				.then(() => {
					tryAgain();
					navigate("../show-result", { relative: "path" });
				})
				.catch(() => {
					toast({
						title: t("Something went wrong") + ".",
						description: "",
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			startContentSharing(
				eventStorage.activities[eventStorage.activeContentIndex].id,
				"next"
			)
				.then(() => {
					setBuzzerStorage((prev) => ({
						...prev,
						currentIndex: prev.currentIndex + 1,
					}));
					tryAgain();
				})
				.catch(() => {
					toast({
						title: t("Something went wrong") + ".",
						description: "",
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const handleUserStopSubmit = () => {
		if (userSelected.user) {
			setLoading(true);
			const currentActivity =
				eventStorage.activities[eventStorage.activeContentIndex];
			stopUser(
				currentActivity.id,
				+buzzerStorage.questions[buzzerStorage.currentIndex].id,
				+userSelected.user.user_id
			)
				.then(() => {
					tryAgain();
				})
				.catch(() => {})
				.finally(() => setLoading(false));
		}
	};

	return (
		<div className="w-[400px] flex-shrink-0 pr-5 h-[calc(100vh-106px-40px)]">
			{tempUser ? (
				<div
					className={`w-full h-full flex items-center justify-center gap-[30px] flex-col ${
						userSelected.adminSelecting || userSelected.userSelecting
							? "bg-violet-100 text-dolphin-700"
							: userSelected.selectionState
							  ? "bg-green-600 text-white"
							  : "bg-orange-600 text-white"
					} rounded-2xl`}
				>
					<img
						src={`${tempUser?.img}`}
						alt="user"
						className="w-[100px] h-[100px]"
					/>
					<p className="text-3xl font-bold">{tempUser?.firstname}</p>
					{userSelected.userSelecting &&
						(!userSelected.adminSelecting ? (
							<Button
								className="violet-btn base-btn rounded w-[72px] h-[72px] disabled:!bg-violet-500"
								isDisabled={loading}
								onClick={handleUserStopSubmit}
							>
								<Clock />
							</Button>
						) : (
							// <Circle className="w-[72px] h-[72px] bg-violet-600 flex items-center justify-center">
							// </Circle>
							!userSelected.user && (
								<Button
									onClick={handleUserSelection}
									className="violet-btn base-btn !text-2xl leading-7 font-bold w-[267px]"
									size="md"
									isDisabled={loading}
								>
									{t("Ask to answer")}
								</Button>
							)
						))}
					{userSelected.user && !userSelected.userSelecting && (
						<>
							{userSelected.selectionState ? (
								<Button
									onClick={nextQuestion}
									className="base-btn outline-btn !px-[66px] !text-2xl leading-7 font-bold text-white"
									size="md"
									variant={"outline"}
									isDisabled={loading}
								>
									<SmallRocket />
								</Button>
							) : (
								<Button
									onClick={tryAgain}
									className="base-btn outline-btn !px-[66px] !text-2xl leading-7 font-bold text-white"
									size="md"
									variant={"outline"}
								>
									{t("Try Again")}
								</Button>
							)}
						</>
					)}
				</div>
			) : (
				<div className="flex flex-col gap-[10px] px-5 w-[400px] self-stretch max-h-[calc(100vh-106px-40px)] overflow-y-scroll">
					{userLive.users.map(
						(user) =>
							user.user_type !== "admin" && (
								<UserChip
									key={user.user_id}
									username={`${user.firstname} ${user.lastname}`}
									img={user.img || ""}
									onClick={() => handleOpenUser(user)}
									loading={loading}
								/>
							)
					)}
				</div>
			)}
		</div>
	);
};

export default UsersObserver;
