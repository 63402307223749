import { Button } from "@chakra-ui/react";
import Modal from "../../components/Modal";
import Bell from "../../icons/Bell";
import { useTranslation } from "react-i18next";

type Props = {
	isOpen: boolean;
	loading: boolean;
	onToggle: () => void;
	onClose: () => void;
	onAccept: () => void;
	onCancel: () => void;
};

const NotLiveModal = ({
	isOpen,
	onClose,
	onToggle,
	onAccept,
	onCancel,
	loading,
}: Props) => {

	const { t } = useTranslation();

	return (
		<Modal isOpen={isOpen} variant="popup" onClose={onClose}>
			<div className="flex flex-col h-full justify-between">
				<div className="flex items-center flex-col gap-5">
					<span className="py-5">
						<Bell />
					</span>
					<p className="text-2xl font-bold leading-7 text-dolphin-700 text-center">
						{t("You’re not sharing content")}
					</p>
				</div>
				<div className="flex flex-col items-center gap-10">
					<Button
						className={`violet-btn btn-base w-full !py-4 font-bold`}
						size={"md"}
						isDisabled={loading}
						onClick={() => {
							onAccept();
						}}
					>
						{t("Start Sharing")}
					</Button>
					<Button
						onClick={() => {
							onToggle();
							onCancel();
						}}
						isDisabled={loading}
					>
						{t("I'm just looking to warm up")}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default NotLiveModal;
