import { FaSpinner } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import eventKeyStore from "../../store/eventKey";
import { useEffect } from "react";

const Index = () => {
	const { token } = useParams();
	const [, setEventKeyStore] = useRecoilState(eventKeyStore);

	useEffect(() => {
		if (!token) {
			window.location.href = "/un-authorized-access";
		} else {
			setEventKeyStore({
				key: token,
			});
			window.location.href = "/";
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="h-screen w-full flex items-center justify-center">
			<FaSpinner className="text-4xl text-dolphin-600 animate-spin" />
		</div>
	);
};

export default Index;
