import Chip from "../../../../shared/Chip";
import { useRecoilValue } from "recoil";
import OptionObserver from "./Option";
import surveyState from "../store";

const ChangeObserver = () => {
	const data = useRecoilValue(surveyState);

	return (
		<div className="flex flex-col gap-5 h-[calc(100vh-106px-134px-25px)] overflow-y-scroll custom-scroll">
			{data.data.map((q, i) => (
				<Chip
					className="!p-4 rounded bg-violet-100 flex items-start justify-between flex-col gap-[5px]"
					key={q.id}
				>
					<div className="flex flex-col gap-[10px] w-full">
						<Chip className="bg-white text-dark-mid text-sm !rounded-[60px] px-4 py-[6px] w-fit">
							{i + 1}/{data.data.length}
						</Chip>
						<h3 className="text-lg font-bold text-dolphin-700 leading-[21px]">
							{q.question}
						</h3>
						<p
							className="text-sm text-dark-light leading-4"
							dangerouslySetInnerHTML={{ __html: q.description }}
						/>
					</div>
					{/* // ! have to calculate the width cause it's not fixed width + it's contained in a non fixed width container */}
					<div className="flex items-center gap-5 flex-row w-full max-w-[calc(100vw-80px)] overflow-x-scroll custom-scroll">
						{q.options.map((op, j) => (
							<OptionObserver
								percent={Math.trunc(op.option_percentage)}
								key={op.id}
								optionMark={j === 0 ? "A" : j === 1 ? "B" : j === 2 ? "C" : "D"}
								option={op.option}
							/>
						))}
					</div>
				</Chip>
			))}
		</div>
	);
};

export default ChangeObserver;
