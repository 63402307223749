import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL || "",
  headers: {
    "Content-Type": "application/json",
    timeout: 6000,
  },
});

instance.interceptors.request.use((config) => {
  config.headers.token = `abve_2024`;
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      // Handle unauthorized access
      localStorage.clear();
      window.location.href = "/un-authorized-access";
    }

    return Promise.reject(error);
  }
);

export default instance;
