import { atom } from "recoil";

type DisplayType = "grid" | "list";

const displayType = atom<DisplayType>({
  key: "displayType",
  default: "grid",
});

export default displayType;
