import React from "react";

const ControlButton = ({
	children,
	onClick,
	loading,
}: {
	children: string;
	onClick: () => void;
	loading: boolean;
}) => {
	return (
		<button
			onClick={onClick}
			className="bg-violet-600 px-16 py-[11px] text-white text-lg font-bold leading-[21.6px] rounded disabled:bg-violet-300"
			disabled={loading}
		>
			{children}
		</button>
	);
};

export default ControlButton;
