import { useRecoilValue } from "recoil";
import { activityIndicatorSelector } from "../../store/eventState";

const ActivityIndicator = () => {
	const Indicator = useRecoilValue(activityIndicatorSelector);

	if (Indicator) {
		return <Indicator />;
	}

  else return <></>
};

export default ActivityIndicator;
