import Chip from "../../../../shared/Chip";

type Props = {
	option: string;
	optionMark: string;
	percent: number;
};

const Option = ({ option, optionMark, percent }: Props) => {
	return (
		<Chip className="bg-white rounded !p-4 h-[120px] w-[190px] flex flex-col justify-between">
			<h4 className="text-sm leading-4 text-dolphin-700">{option}</h4>
			<div className="flex items-center justify-between">
				<p className="text-[11px] leading-[14px] text-white bg-violet-500 rounded !w-[20px] !h-[20px] flex items-center justify-center">
					{optionMark}
				</p>
				<Chip className="bg-violet-100 px-[6xp] py-[3px] flex items-center gap-[10px]">
					<div className="rounded w-[78px] bg-white flex-1 h-[7px] overflow-hidden transition-all duration-300">
						<span
							style={{ width: (percent / 100) * 78 || 0 }}
							className="h-[7px] bg-violet-600 w-[20px] block transition-all duration-200"
						/>
					</div>
					<p className="text-[11px] leading-[14px] text-dark-mid w-7 text-right">{percent}%</p>
				</Chip>
			</div>
		</Chip>
	);
};

export default Option;
