import { useRecoilState } from "recoil";
import CarouselCard from "./CarouselCard";
import slideShowStore from "./store";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import resultsStore from "./slideShowResult/store";
import { startContentSharing } from "../../../lib/services/events";
import loadingStateSlides from "./store/loadingState";

const SlideShowCarousel = () => {
	const toast = useToast();

	const { t } = useTranslation();

	const [, setResultsStore] = useRecoilState(resultsStore);
	const [slides, setSlides] = useRecoilState(slideShowStore);
	const [loading, setLoading] = useRecoilState(loadingStateSlides);

	const handleChangeSlide = (subIndex: number, prevIndex: number) => {
		if (!loading) {
			setLoading(true);
			setSlides((prev) => ({
				...prev,
				currentIndex: subIndex,
			}));
			startContentSharing(slides.contentId, "show", slides.slides[subIndex].id)
				.then((data) => {
					setResultsStore(data.data.data.results);
				})
				.catch(() => {
					toast({
						title: t("Something went wrong") + ".",
						description: t("Please try again") + ".",
						status: "error",
						duration: 9000,
						isClosable: true,
					});
					setSlides((prev) => ({
						...prev,
						currentIndex: prevIndex,
					}));
				})
				.finally(() => {
					setTimeout(() => setLoading(false), 3000);
				});
		}
	};

	return (
		<div className="overflow-x-scroll custom-scroll flex gap-5">
			{slides.slides.map((slide, i) => {
				const state =
					slides.currentIndex > i
						? "USED"
						: slides.currentIndex === i
						  ? "ONLINE_NOW"
						  : slides.currentIndex + 1 === i
						    ? "NEXT"
						    : "NOT_USED";

				return (
					<CarouselCard
						state={state}
						title={slide.title}
						onClick={() => handleChangeSlide(i, slides.currentIndex)}
						key={slide.id}
						loading={loading}
					/>
				);
			})}
		</div>
	);
};

export default SlideShowCarousel;
