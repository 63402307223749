import { useNavigate } from "react-router-dom";
import Survey from "../../../icons/Survey";
import { Button, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import eventState from "../../../store/eventState";
import quizStore from "./store";
import { startContentSharing } from "../../../lib/services/events";

const QuizShowResults = () => {
	const navigate = useNavigate();
	const toast = useToast();

	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const eventStorage = useRecoilValue(eventState);
	const [, setQuizState] = useRecoilState(quizStore);

	const handleShowResults = () => {
		const currentActivity =
			eventStorage.activities[eventStorage.activeContentIndex];
		setLoading(true);
		startContentSharing(currentActivity.id, "show_results")
			.then((data) => {
				const rData = data.data.data.showable_content.questions.map(
					(item: {
						text: string;
						description: string;
						results: {
							correct_answer: 0 | 1;
							false: number;
							true: number;
							true_count: number;
							false_count: number;
						};
					}) => ({
						text: item.text,
						description: item.description,
						results: {
							isTrue: item.results.correct_answer,
							false: item.results.false,
							true: item.results.true,
							true_count: item.results.true_count,
							false_count: item.results.false_count,
						},
					})
				);
				console.log(rData);
				setQuizState((prev) => ({
					...prev,
					data: rData,
				}));
				navigate("../results", { relative: "path" });
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className="bg-blaze-600 flex-1 m-5 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="stroke-white scale-[2.2]">
				<Survey />
			</p>
			<div className="text-white max-w-[820px] text-3xl text-center">
				<h3>{t("All users have voted!")}</h3>
			</div>
			<Button
				variant={"outline !transition-all !duration-300 font-bold"}
				className="outline-btn w-[400px]"
				size={"md"}
				onClick={() => handleShowResults()}
				isDisabled={loading}
			>
				{t("Display results")}
			</Button>
		</div>
	);
};

export default QuizShowResults;
