import { useNavigate } from "react-router-dom";
import { Button, useToast } from "@chakra-ui/react";
import Buzzer from "../../../icons/Buzzer";
import { useEffect, useState } from "react";
import buzzerStore from "./store/buzzerStore";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { startContentSharing } from "../../../lib/services/events";
import eventState from "../../../store/eventState";

const ShowResultBuzzer = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [eventStorage, setEventStorage] = useRecoilState(eventState);
	const [, setBuzzerStore] = useRecoilState(buzzerStore);

	const [loading, setLoading] = useState(false);

	const handleShowResults = () => {
		setLoading(true);
		startContentSharing(
			eventStorage.activities[eventStorage.activeContentIndex].id,
			"show_results"
		)
			.then((data) => {
				const winner = data.data.data.results.winner[0];
				setBuzzerStore((prev) => ({
					...prev,
					winner: {
						first_name: winner.first_name,
						id: winner.id,
						image: winner.image,
						last_name: winner.last_name,
					},
				}));
				setEventStorage((prev) => ({
					...prev,
					activities: prev.activities.map((item, index) => ({
						...item,
						state: index === prev.activeContentIndex ? "played" : item.state,
					})),
				}));
				navigate("../results", { relative: "path" });
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: "",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setBuzzerStore((prev) => ({
			...prev,
			currentIndex: 0,
			questions: [],
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="bg-pink-600 flex-1 m-5 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="scale-[3] stroke-white">
				<Buzzer />
			</p>
			<div className="text-white max-w-[820px] text-3xl text-center">
				<h3>{t("Ready to go") + "!"}</h3>
			</div>
			<Button
				variant={"outline !transition-all !duration-300 font-bold"}
				className="outline-btn w-[400px]"
				size={"md"}
				isDisabled={loading}
				onClick={handleShowResults}
			>
				{t("Display results")}
			</Button>
		</div>
	);
};

export default ShowResultBuzzer;
