import ChangeObserver from "./ChangeObserver/ChangeObserver";
import Progress from "./ChangeObserver/Progress";
import useSurveyChannel from "../../../hooks/events/useSurveyChannel";

const SurveyObserver = () => {
	useSurveyChannel();

	return (
		<div className="relative">
			<div className="bg-white p-5 fixed w-full">
				<Progress />
			</div>
			<div className="flex flex-col gap-[10px] !px-5 !pb-5 mt-[135px]">
				<ChangeObserver />
			</div>
		</div>
	);
};

export default SurveyObserver;
