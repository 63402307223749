import Circle from "../../shared/Circle";
import Chip from "../../shared/Chip";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import Counter from "../../components/animatedCounter/Counter";
import userLiveStore from "../../store/userLiveStore";
import Bars from "../../icons/Bars";
// import {
// 	Button,
// 	Image,
// 	Menu,
// 	MenuButton,
// 	MenuItem,
// 	MenuList,
// 	useToast,
// } from "@chakra-ui/react";
// import { FaChevronDown, FaUserAlt } from "react-icons/fa";
// import { TiTimes } from "react-icons/ti";
// import { getAllUsers, kickUsers } from "../../lib/services/users";
// import { useState } from "react";

const ParticipantViewer = () => {
	// const toast = useToast();
	const [users, ] = useRecoilState(userLiveStore);

	const { t } = useTranslation();

	// const [loading, setLoading] = useState(false);

	// const allUsers = async () => {
	// 	getAllUsers()
	// 		.then((data) => {
	// 			setUsers((prev) => ({
	// 				...prev,
	// 				allUsers: data.data.data.map((item: any) => ({
	// 					firstname: item.first_name,
	// 					lastname: item.last_name,
	// 					user_id: item.id,
	// 					user_type: "user",
	// 					img: item.image,
	// 				})),
	// 			}));
	// 		})
	// 		.catch(() => {});
	// };

	// const kickUser = (id: string) => {
	// 	setLoading(true);
	// 	kickUsers(id)
	// 		.then(async () => {
	// 			await allUsers();
	// 		})
	// 		.catch(() =>
	// 			toast({
	// 				title: t("Something went wrong") + ".",
	// 				description: "",
	// 				status: "error",
	// 				duration: 9000,
	// 				isClosable: true,
	// 			})
	// 		)
	// 		.finally(() => setLoading(false));
	// };

	return (
		<Chip className="text-dark-light stroke-dark-light flex items-center gap-[6px] self-stretch text-lg font-bold pr-4">
			<Circle className="min-w-[50px] w-[50px] max-w-[50px] min-h-[50px] h-[50px] max-h-[50px] flex items-center justify-center self-stretch bg-white">
				<Bars />
			</Circle>
			<Counter value={users.count} />
			<div className="flex min-w-fit flex-col items-start text-[12px] font-normal leading-[11px]">
				<div className="flex-1">{t("USER")}</div>
				<div className="flex-1">{t("ONLINE")}</div>
			</div>
			{/* <Menu>
				<MenuButton
					as={Button}
					className="text-violet-600 disabled:text-dolphin-500"
					rightIcon={<FaChevronDown />}
					isDisabled={users.allUsers.length < 1}
				></MenuButton>
				<MenuList mt={2}>
					{users.allUsers.map(
						(user) =>
							user.user_type !== "admin" && (
								<MenuItem
									display={"flex"}
									alignItems={"center"}
									gap="8px"
									justifyContent={"space-between"}
									key={user.user_id}
									className="cursor-default"
									isDisabled={loading}
								>
									<div className="flex items-center gap-1">
										{user.img ? (
											<span className="relative">
												<Image
													boxSize="2.5rem"
													borderRadius="full"
													src={user.img}
													alt={user.firstname}
													mr="12px"
												/>
												<Circle
													className={`w-3 h-3 absolute ${
														users.users.find(
															(u) => +u.user_id === +user.user_id
														)
															? "bg-green-700"
															: "bg-dolphin-700"
													} rounded bottom-0 right-3 flex items-center justify-center`}
												/>
											</span>
										) : (
											<FaUserAlt className="mr-[18px] ml-2" />
										)}
										<p
											className="text-dolphin-600"
										>
											{user.firstname} {user.lastname}
										</p>
									</div>
									<Circle className="hover:bg-slate-300">
										<TiTimes
											className="text-red-800 cursor-pointer text-2xl"
											onClick={() => kickUser(user.user_id.toString())}
										/>
									</Circle>
								</MenuItem>
							)
					)}
				</MenuList>
			</Menu> */}
		</Chip>
	);
};

export default ParticipantViewer;
