import { atom } from "recoil";

export type ListTypes = {
	overall_progress: string;
	options: {
		id: number;
		text: string;
	}[];
	data: {
		option_id: number;
		text: string;
		score: { all: number };
		scale: { all: number };
	}[];
};

export const listDefault: ListTypes = {
	overall_progress: "",
	options: [],
	data: [],
};

const listStore = atom<ListTypes>({
	key: "listStoreKey",
	default: listDefault,
});

export default listStore;
