import { Button, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import Survey from "../../../icons/Survey";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import resultCarousel from "./result/store";
import { useTranslation } from "react-i18next";
import eventState, { SurveyActivity } from "../../../store/eventState";
import { startContentSharing } from "../../../lib/services/events";
import surveyState from "./store";
import { getSurveyResults, ResponseType } from "../../../lib/services/survey";

type SurveyStartApi = {
	action: "start";
	content_id: number;
	content_type: 3;
	content_type_name: "survey";
	showable_content: {
		description: string;
		id: number;
		questions: {
			description: string;
			id: number;
			text: string;
			type_name: "Single" | "Multiple";
			options: {
				id: number;
				option_percentage: number;
				option_progress: number;
				text: string;
			}[];
		}[];
	};
};

const SurveyStart = () => {
	const toast = useToast();
	const navigate = useNavigate();

	const [, setCarouselData] = useRecoilState(resultCarousel);
	const eventStorage = useRecoilValue(eventState);
	const [, setData] = useRecoilState(surveyState);

	const [loading, setLoading] = useState(false);

	const { currentIndex } = useParams();

	const index = +(currentIndex as string);
	const currentActivity =
		eventStorage.activities[eventStorage.activeContentIndex];
	const { t } = useTranslation();

	useEffect(() => {
		const currentEvent = eventStorage.activities[index] as SurveyActivity;
		setLoading(true);
		getSurveyResults(currentActivity.id)
			.then((data) => {
				const cData = data.data as ResponseType;
				console.log(cData.data.overall_progress);
				setData((prev) => ({
					...prev,
					overall_percentage: Number.parseInt(cData.data.overall_progress),
				}));
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
		setCarouselData((prev) => ({
			...prev,
			total: currentEvent.questions.length,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSurveyStart = () => {
		setLoading(true);
		startContentSharing(currentActivity.id, "start")
			.then((data) => {
				const rData = data.data.data as SurveyStartApi;
				setData((prev) => ({
					...prev,
					data: rData.showable_content.questions.map((q) => ({
						description: q.description,
						id: q.id.toString(),
						question: q.text,
						options: q.options.map((op) => ({
							id: op.id.toString(),
							option: op.text,
							option_percentage: op.option_percentage,
							option_progress: op.option_progress,
						})),
					})),
				}));
				navigate("observer");
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className="bg-denim-600 flex-1 m-5 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="stroke-white scale-[2.2]">
				<Survey />
			</p>
			<div className="text-white max-w-[820px] text-3xl text-center">
				<h3>{eventStorage.activities[index].title}</h3>
				<p
					className="font-bold"
					dangerouslySetInnerHTML={{
						__html: eventStorage.activities[index].description,
					}}
				/>
			</div>
			<Button
				variant={"outline !transition-all !duration-300 font-bold"}
				className="outline-btn w-[400px]"
				size={"md"}
				onClick={handleSurveyStart}
				isDisabled={loading}
			>
				{t("Start")}
			</Button>
		</div>
	);
};

export default SurveyStart;
