import axios from "../clients/axiosInstance";
import END_POINT from "../api/END_POINTS";

const { V1 } = END_POINT;

export const selectUser = (id: number, question: number, user: number) => {
	return axios.post(V1.SELECT_USER(id), {
		question,
		user,
	});
};

export const stopUser = (id: number, question: number, user: number) => {
	return axios.post(V1.STOP_USER(id), {
		question,
		user,
	});
};
