import { useTranslation } from "react-i18next";
import Chip from "../../../../shared/Chip";

const ResultCard = ({
	// index,
	isTrue,
	title,
	description,
	falseCount,
	trueCount,
}: {
	index: number;
	title: string;
	description: string;
	trueCount: number;
	falseCount: number;
	isTrue: boolean;
}) => {
	const { t } = useTranslation();

	return (
		<Chip
			className={`flex flex-col justify-between !p-4 rounded gap-4 h-[300px] ${
				isTrue ? "bg-green-200" : "bg-orange-200"
			}`}
		>
			<div className="flex items-center justify-between">
				<div
					className={`${
						isTrue ? "bg-green-600" : "bg-orange-600"
					} text-white rounded text-base leading-5 px-3 py-[2px]`}
				>
					{isTrue ? t("TRUE") : t("FALSE")}
				</div>
				<div className="flex items-center gap-2">
					<Chip className="!py-[2px] !px-2 bg-white text-green-600 flex items-center gap-2">
						<p className="font-bold !text-base leading-5">{trueCount}</p>{" "}
						<p className="text-xs leading-[14px]">{t("TRUE")}</p>
					</Chip>
					<Chip className="!py-[2px] !px-2 bg-white text-orange-600 flex items-center gap-2">
						<p className="font-bold !text-base leading-5">{falseCount}</p>{" "}
						<p className="text-xs leading-[14px]">{t("FALSE")}</p>
					</Chip>
				</div>
			</div>
			<div className="flex flex-col gap-2 text-dolphin-700 flex-1 max-h-full overflow-y-scroll custom-scroll">
				<h4 className="text-lg leading-[21.6px] font-bold">{title}</h4>
				<p dangerouslySetInnerHTML={{ __html: description }} />
			</div>
		</Chip>
	);
};

export default ResultCard;
