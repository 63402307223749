import {
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AiFillAlert } from "react-icons/ai";

const ResetContentModal = ({
	isOpen,
	onClose,
	handleMutate,
	loading,
}: {
	onClose: () => void;
	isOpen: boolean;
	handleMutate: () => void;
	loading: boolean;
}) => {
	const { t } = useTranslation();

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnEsc={false}
			closeOnOverlayClick={false}
		>
			<ModalOverlay />
			<ModalContent>
				<div className="w-full text-center  text-red-700 flex-1 pt-5 pb-6">
					<AiFillAlert className="mx-auto text-7xl mb-2" />
					<span>{t("This Action Can't Be Reverted")}</span>
				</div>
				<ModalBody className="text-xl font-bold">
					{t("Are You Sure, You Want To Reset Results For This Activity ?")}
				</ModalBody>

				<ModalFooter className="gap-5">
					<Button onClick={onClose} isDisabled={loading} isLoading={loading}>
						{t("Close")}
					</Button>
					<Button
						onClick={handleMutate}
						className="border-[1px] border-red-600 text-red-600 bg-red-100 hover:bg-red-600 hover:text-white px-4 py-2 transition-all duration-300"
						isDisabled={loading}
						isLoading={loading}
					>
						{t("Sure")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ResetContentModal;
