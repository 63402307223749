import SlideShowCarousel from "./SlideShowCarousel";
import SlideShowDescriptionControl from "./SlideShowDescriptionControl";
import ImageCarousel from "./ImageCarousel";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import slideShowStore from "./store";

const SlideShowPlayer = () => {
	const [, setSlides] = useRecoilState(slideShowStore);

	useEffect(() => {
		return () => {
			setSlides(prev => ({
				...prev,
				currentIndex: 0,
				total: 0,
			}));
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex-1 p-5 flex flex-col gap-5">
			<div className="grid grid-cols-3 gap-5 h-[calc(100vh-106px-150px-70px)]">
				<div className="col-span-2">
					<ImageCarousel />
				</div>
				<SlideShowDescriptionControl />
			</div>
			<SlideShowCarousel />
		</div>
	);
};

export default SlideShowPlayer;
