import Chip from "../../../../shared/Chip";
import { OptionSurveyStoreType } from "../store";
import Option from "./Option";

type Props = {
	currentSlide: number;
	totalSlides: number;
	results: number[];
	surveyData: {
		id: string;
		question: string;
		description: string;
		options: OptionSurveyStoreType[];
	};
};

const ResultCard = ({
	currentSlide,
	surveyData,
	totalSlides,
	results,
}: Props) => {
	return (
		<Chip className="rounded bg-dolphin-100 p-5 flex flex-col gap-10 w-full h-full max-h-[calc(100vh-40px-106px-63px)]">
			<div className="flex flex-col gap-5">
				<Chip className="px-4 py-[6px] text-lg text-white !bg-dark-mid w-fit">
					{currentSlide}/{totalSlides}
				</Chip>
				<h3 className="text-3xl font-bold text-dolphin-700 leading-9">
					{surveyData.question}
				</h3>
				<p
					className="text-[24px] text-dark-light pt-[5px] leading-7"
					dangerouslySetInnerHTML={{ __html: surveyData.description }}
				/>
			</div>
			<div className="grid grid-cols-12 gap-5 overflow-y-scroll custom-scroll">
				{surveyData.options.map((op, i) => {
					const max = results.reduce((a, b) => Math.max(a, b), -Infinity);
					return (
						<div
							key={i}
							className="col-span-12 sm:col-span-6 lg:col-span-3 2xl:col-span-2 self-stretch row-span-1"
						>
							<Option
								key={i}
								option={op.option}
								percentage={Math.trunc(Number(op.option_progress))}
								mostChose={max === Number(op.option_progress)}
							/>
						</div>
					);
				})}
			</div>
		</Chip>
	);
};

export default ResultCard;
