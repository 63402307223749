import Chip from "../../../../shared/Chip";

const Option = ({
	option,
	percentage,
	mostChose,
}: {
	option: string;
	percentage: number;
	mostChose: boolean;
}) => {
	return (
		<Chip
			className={`flex flex-col justify-between !p-4 rounded gap-8 border-4 border-solid h-full ${"bg-white border-violet-600"}`}
		>
			<div className="flex flex-col gap-4">
				<Chip
					className={`text-center font-bold text-[20px] leading-6 w-fit px-4 py-[6px] border-[1px]  border-violet-600 ${
						mostChose ? "bg-violet-600 text-white" : "bg-white text-violet-600"
					}`}
				>
					{percentage}%
				</Chip>
				<h4 className="text-lg text-dolphin-700 leading-[21.6px] font-bold line-clamp-3">
					{option}
				</h4>
			</div>
		</Chip>
	);
};

export default Option;
