import { atom } from "recoil";

type ResultStore = {
	overall_progress: string;
	score: {
		text: string;
		percentage: string;
	}[];
};

export const defaultResultStore: ResultStore = {
	overall_progress: "",
	score: [],
};

const resultStore = atom<ResultStore>({
	key: "gameResultStore",
	default: defaultResultStore,
});

export default resultStore;
