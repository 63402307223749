/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import pusherInstance from "../../lib/clients/pusherInstance";
import { useRecoilState } from "recoil";
import listStore from "../../pages/activities/list/store";

export type Actions =
	| "start"
	| "show"
	| "end"
	| "show_results"
	| "prev"
	| "next";

type normalMsg = {
	content_id: number;
	content_type: number;
	content_type_name: string;
	action: Actions;
	showable_content: {
		id: number;
		title: string;
		description: string;
		type: number;
		type_name: "Make-Your-List";
		// overall_progress: string;
		// options: {
		// 	id: number;
		// 	text: string;
		// }[];
	};
	question_id: null;
	// results: {
	// 	overall_progress: string;
	// 	score: {
	// 		option_id: number;
	// 		score: string;
	// 		text: string;
	// 	}[];
	// };
};

type scoreMsg = {
	action: "user-submit";
	overall_progress: string;
	score: {
		option_id: number;
		score: string;
		text: string;
	}[];
};

type MsgType = {
	data: normalMsg | scoreMsg;
	eventType: string;
};

const useListChannel = () => {
	const [, setListState] = useRecoilState(listStore);

	const channel_name = "private-admin-presenter";

	useEffect(() => {
		const pusher = pusherInstance();

		const listChannel = pusher.subscribe(channel_name);

		listChannel.bind("make-your-list", (data: MsgType) => {
			switch (data.data.action) {
				case "user-submit":
					const cData = data.data as scoreMsg;
					setListState((prev) => ({
						...prev,
						overall_progress: cData.overall_progress,
					}));
					break;
				default:
					break;
			}
		});
	}, []);
};

export default useListChannel;
