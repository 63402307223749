import { useNavigate } from "react-router-dom";
import { Button, useToast } from "@chakra-ui/react";
import Rocket from "../../../icons/Rocket";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useRecoilState } from "recoil";
import eventState, { MakeYourListActivity } from "../../../store/eventState";
import { startContentSharing } from "../../../lib/services/events";
import listStore from "./store";

const ListShowResults = () => {
	const navigate = useNavigate();
	const [, setListState] = useRecoilState(listStore);

	const { t } = useTranslation();

	const toast = useToast();

	const [loading, setLoading] = useState(false);

	const [eventStorage, setEventStorage] = useRecoilState(eventState);

	const currentEvent = eventStorage.activities[
		eventStorage.activeContentIndex
	] as MakeYourListActivity;

	const handleStartContentShare = () => {
		setLoading(true);
		startContentSharing(currentEvent.id, "show_results")
			.then((data) => {
				setEventStorage((prev) => ({
					...prev,
					activities: prev.activities.map((item, j) =>
						prev.activeContentIndex === j ? { ...item, state: "played" } : item
					),
				}));
				setListState((prev) => ({
					...prev,
					data: data.data.data.results.score,
				}));
				navigate("../results", { relative: "path" });
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className="bg-red-600 flex-1 m-5 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="fill-white ">
				<Rocket />
			</p>
			<div className="text-white max-w-[820px] text-3xl text-center">
				<h3>{t("All users have finished!")}</h3>
			</div>
			<Button
				variant={"outline !transition-all !duration-300 font-bold"}
				className="outline-btn w-[400px]"
				size={"md"}
				isDisabled={loading}
				onClick={handleStartContentShare}
			>
				{t("Display results")}
			</Button>
		</div>
	);
};

export default ListShowResults;
