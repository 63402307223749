import { useTranslation } from "react-i18next";
import Chip from "../../../shared/Chip";
import { useRecoilValue } from "recoil";
import buzzerStore from "./store/buzzerStore";
import ConfettiExplosion from "react-confetti-explosion";

const BuzzerWinner = () => {
	const { t } = useTranslation();
	const { winner } = useRecoilValue(buzzerStore);

	return (
		<Chip className="!rounded !bg-green-600 flex-col m-5 text-white flex-1 h-full flex items-center justify-center gap-5">
			<ConfettiExplosion
				force={0.8}
				duration={3000}
				particleCount={250}
				width={1600}
			/>
			<img
				src={winner.image}
				className="w-[248px] h-[248px]"
				alt="winner winner chicken dinner"
			/>
			<p className="text-[42px] leading-[50px]">{t("The winner is")}</p>
			<h3 className="text-3xl font-bold leading-[36px]">
				{winner.first_name} {winner.last_name}
			</h3>
		</Chip>
	);
};

export default BuzzerWinner;
