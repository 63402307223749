import { Button, useToast } from "@chakra-ui/react";
import Chip from "../../../shared/Chip";
import { useRecoilState } from "recoil";
import slideShowStore from "./store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import resultsStore from "./slideShowResult/store";
import { startContentSharing } from "../../../lib/services/events";
import loadingStateSlides from "./store/loadingState";

const SlideShowDescriptionControl = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [, setResultsStore] = useRecoilState(resultsStore);
	const [slides, setSlides] = useRecoilState(slideShowStore);
	const [loading, setLoading] = useRecoilState(loadingStateSlides);

	const handleChangeSlide = (subIndex: number, prevIndex: number) => {
		setLoading(true);
		setSlides((prev) => ({
			...prev,
			currentIndex: subIndex,
		}));
		startContentSharing(slides.contentId, "show", slides.slides[subIndex].id)
			.then((data) => {
				setResultsStore(data.data.data.results);
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
				setSlides((prev) => ({
					...prev,
					currentIndex: prevIndex,
				}));
			})
			.finally(() => {
				setTimeout(() => setLoading(false), 3000);
			});
	};

	const handleSlideShowEnd = () => {
		setLoading(true);

		startContentSharing(slides.contentId, "end")
			.then(() => {
				navigate("../end", { relative: "path" });
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className="flex flex-col gap-5 !flex-1 !shrink-0 max-h-[calc(100vh-106px-150px-70px)] overflow-h-auto">
			<div className="flex flex-col gap-5 w-full ">
				<Button
					variant={"primary"}
					className={`orange-btn btn-base !py-4 ${
						slides.currentIndex === 0
							? "pointer-events-none cursor-not-allowed"
							: ""
					}`}
					size="md"
					isDisabled={slides.currentIndex === 0 || loading}
					onClick={() => {
						if (!(slides.currentIndex === 0)) {
							handleChangeSlide(slides.currentIndex - 1, slides.currentIndex);
						}
					}}
				>
					{t("Prev")}
				</Button>
				<Button
					variant={"primary"}
					className="cyan-btn btn-base !py-4"
					size="md"
					isDisabled={loading}
					onClick={() => {
						if (slides.currentIndex + 1 === slides.total) {
							handleSlideShowEnd();
						} else {
							handleChangeSlide(slides.currentIndex + 1, slides.currentIndex);
						}
					}}
				>
					{slides.currentIndex + 1 === slides.total
						? t("Finish Slides")
						: t("Next")}
				</Button>
			</div>
			<Chip className="flex flex-col gap-5 flex-1 h-full !bg-dolphin-100 rounded !p-5 w-full ">
				<Chip className="text-[24px] px-4 py-[3px] !bg-dark-light text-white w-fit">
					{slides.currentIndex + 1}/{slides.total}
				</Chip>
				<h4 className="text-[24px] text-dark-mid font-bold tracking-[-2%]">
					{slides.slides[slides.currentIndex].title}
				</h4>
				<p
					className="text-base leading-[19.3px] text-dark-light"
					dangerouslySetInnerHTML={{
						__html: slides.slides[slides.currentIndex].description,
					}}
				/>
			</Chip>
		</div>
	);
};

export default SlideShowDescriptionControl;
