const ToggleButton = ({
  children,
  className,
  active,
  extraActive,
  extraUnActive,
  ...rest
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  active: boolean;
  extraActive?: string;
  extraUnActive?: string;
}) => {
  return (
    <button
      className={`rounded border-[1px] border-solid px-4 py-[11px] text-sm transition-all duration-300 ${
        active
          ? `border-violet-600 bg-violet-600 text-white ${extraActive}`
          : `border-violet-600 hover:bg-violet-600 hover:text-white ${extraUnActive}`
      } ${className}`}
      {...rest}>
      {children}
    </button>
  );
};

export default ToggleButton;
