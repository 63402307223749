import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import resultCarousel from "./store";
import surveyState from "../store";
import ResultCard from "./ResultCard";
import "swiper/css";

const Result = () => {
	const swiperRef = useRef<SwiperClass | null>(null);
	const [carouselData] = useRecoilState(resultCarousel);
	const [surveyData] = useRecoilState(surveyState);

	useEffect(() => {
		if (swiperRef.current) {
			swiperRef?.current?.slideTo(carouselData.currentIndex);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [carouselData.currentIndex]);

	return (
		<div className="!h-full flex-1 fix-hight flex">
			<Swiper
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper;
				}}
				spaceBetween={50}
				slidesPerView={1}
				autoplay={false}
				draggable={false}
				allowTouchMove={false}
				className="!h-[calc(100vh-106px-63px-40px)]"
			>
				{surveyData.data.map((sur, j) => {
					const results = sur.options.map((item) => +item.option_progress);

					return (
						<SwiperSlide
							key={sur.id}
						>
							<ResultCard
								currentSlide={j + 1}
								results={results}
								surveyData={sur}
								totalSlides={surveyData.data.length}
								key={sur.id}
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default Result;
