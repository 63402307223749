import { atom } from "recoil";
import { Slides } from "../../../../store/eventState";

type slideShowTypes = {
  currentIndex: number,
  contentId: number;
  total: number,
  slides: Slides[]
}

export const defaultSlideShowStore = {
  currentIndex: 0,
  contentId: 0,
  total: 0,
  slides: [],
}

const slideShowStore = atom<slideShowTypes>({
  key: "slideShowStore",
  default: defaultSlideShowStore,
});

export default slideShowStore;