const V1 = {
	GET_AVATARS: "/v1/avatars",
	SET_AVATAR: "/v1/avatars",

	JOIN_EVENT: "/v1/users/join",

	GO_BACK: "/v1/admin/back",

	GET_EVENTS: `/v1/events/1/details`,

	GO_ONLINE: `/v1/events/1/online`,
	GO_OFFLINE: `/v1/events/1/offline`,

	CONTENT_STANDBY: (id: number) => `/v1/presenter/${id}/standby`,
	PRESENT_CONTENT: (id: number) => `/v1/presenter/${id}/present`,

	GET_SLIDESHOW_RESULTS: (id: string) => `/v1/slides/content/${id}/results`,

	KICK_USER: `/v1/users/delete`,
	GET_ALL_USERS: `/v1/events/1/users`,

	SELECT_USER: (id: number) => `/v1/buzzers/${id}/select`,
	STOP_USER: (id: number) => `/v1/buzzers/${id}/stop`,

	POST_USER_STATUS: (id: number | string) => `/v1/users/${id}/online`,

	RESET_RESULTS: (id: number | string) => `/v1/${id}/data/delete`,

	GET_SURVEY_RESULTS: (id: number | string) => `/v1/surveys/${id}/results`,
} as const;

const END_POINT = {
	V1,
} as const;

export default END_POINT;
