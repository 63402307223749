import { atom } from "recoil";

type buzzerStoreType = {
  questions: {
    id: string;
    question: string;
    description: string;
    options: {
      id: string;
      option: string;
      isTheRightAnswer: boolean;
    }[];
  }[];
  currentIndex: number;
  winner: {
    id: number;
    first_name: string;
    last_name: string;
    image: string;
  };
};

const defaultBuzzerStore: buzzerStoreType = {
  questions: [],
  currentIndex: 0,
  winner: {
    first_name: "",
    id: 0,
    image: "",
    last_name: ""
  }
};

const buzzerStore = atom<buzzerStoreType>({
  key: "buzzerStore",
  default: defaultBuzzerStore,
});

export default buzzerStore;
