import { useRecoilState } from "recoil";
import userLiveStore from "../../store/userLiveStore";
import { useEffect } from "react";
import pusherInstance from "../../lib/clients/pusherInstance";
import { getAllUsers } from "../../lib/services/users";

type UserInfo = {
	firstname: string;
	lastname: string;
	user_id: string | number;
	user_type: "admin" | "user";
	img: string | null;
};

interface IMembers {
	[key: number]: UserInfo;
}

type SubscriptionSucceeded = {
	count: number;
	me: {
		id: string;
		info: UserInfo;
	};
	members: IMembers;
};

const usePresenceChannel = () => {
	const [userLive, setUserLive] = useRecoilState(userLiveStore);

	const channel_name = "presence-users-online";

	const allUsers = async () => {
		getAllUsers()
			.then((data) => {
				setUserLive((prev) => ({
					...prev,
					users: data.data.data.map((item: any) => ({
						firstname: item.first_name,
						lastname: item.last_name,
						user_id: item.id,
						user_type: "user",
						img: item.image,
					})),
				}));
			})
			.catch(() => {});
	};

	useEffect(() => {
		const pusher = pusherInstance();

		const subscribeChannel = pusher.subscribe(channel_name);

		subscribeChannel.bind(
			"pusher:member_removed",
			async (data: { id: string; info: UserInfo }) => {
				const users = userLive.users.filter(
					(u) => u.user_id.toString() !== data.id.toString()
				);
				await allUsers();
				setUserLive((prev) => ({
					...prev,
					count: prev.count - 1,
					users,
				}));
			}
		);

		subscribeChannel.bind(
			"pusher:member_added",
			async (data: { id: string; info: UserInfo }) => {
				await allUsers();
				setUserLive((prev) => ({
					...prev,
					count: prev.count + 1,
					users: [...prev.users, data.info],
				}));
			}
		);

		subscribeChannel.bind(
			"pusher:subscription_succeeded",
			async (data: SubscriptionSucceeded) => {
				await allUsers();
				const users: UserInfo[] = [];
				for (const key in data.members) {
					users.push(data.members[key]);
				}

				setUserLive((prev) => ({
					...prev,
					count: data.count - 1,
					users: [...prev.users, ...users],
				}));
			}
		);

		return () => {
			pusher.disconnect();
			pusher.unsubscribe(channel_name);
			pusher.unbind_all();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default usePresenceChannel;
