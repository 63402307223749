// import styles from "./footer.module.css";
import ToggleButton from "../shared/ToggleButton";
import { CiMenuBurger } from "react-icons/ci";
import { GrAppsRounded } from "react-icons/gr";
import { useRecoilState } from "recoil";
import displayType from "../store/displayView";
import { useTranslation } from "react-i18next";
import eventState from "../store/eventState";

const HomeControls = () => {
	const [display, setDisplay] = useRecoilState(displayType);
	const [eState, setEventState] = useRecoilState(eventState);

	const { t } = useTranslation();

	return (
		<div className={`flex w-full items-center justify-between px-5`}>
			<div className="flex items-center gap-5">
				<ToggleButton
					active={eState.currentState === "queued"}
					onClick={() => {
						setEventState((prev) => ({
							...prev,
							currentState: "queued",
						}));
					}}
				>
					{t("Playlist")}
				</ToggleButton>
				<ToggleButton
					active={eState.currentState === "played"}
					onClick={() => {
						setEventState((prev) => ({
							...prev,
							currentState: "played",
						}));
					}}
				>
					{t("Shared content")}
				</ToggleButton>
				<ToggleButton
					active={eState.currentState === "allContents"}
					onClick={() => {
						setEventState((prev) => ({
							...prev,
							currentState: "allContents",
						}));
					}}
				>
					{t("All content")}
				</ToggleButton>
			</div>
			<div className="flex items-center gap-5">
				<ToggleButton
					active={display === "grid"}
					className="rounded !p-[12px]"
					extraUnActive="text-violet-600"
					onClick={() => setDisplay("grid")}
				>
					<GrAppsRounded size={16} />
				</ToggleButton>
				<ToggleButton
					active={display === "list"}
					className="rounded !p-[12px]"
					extraUnActive="text-violet-600"
					onClick={() => setDisplay("list")}
				>
					<CiMenuBurger size={16} />
				</ToggleButton>
			</div>
		</div>
	);
};

export default HomeControls;
