import { atom } from "recoil";

export type LiveUsers = {
  firstname: string;
  lastname: string;
  user_id: number | string;
  user_type: "admin" | "user";
  img: string | null
};

type LiveUsersData = {
  count: number;
  users: LiveUsers[];
  allUsers: LiveUsers[];
};

export const userLiveStoreDefault = {
  count: 0,
  users: [],
  allUsers: [],
};

const userLiveStore = atom<LiveUsersData>({
  key: "userLiveStoreEEE",
  default: userLiveStoreDefault,
});

export default userLiveStore;
