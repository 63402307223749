import React from "react";

const Back = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="*" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7791 6.63595C18.3929 6.24847 17.9337 5.94142 17.4281 5.73258C16.9224 5.52374 16.3804 5.41726 15.8333 5.41928H2.33331L5.91664 1.83595C6.07268 1.67925 6.16007 1.46699 6.1596 1.24585C6.15913 1.02471 6.07084 0.812819 5.91414 0.656784C5.75744 0.500748 5.54518 0.413351 5.32404 0.41382C5.1029 0.414289 4.89101 0.502585 4.73497 0.659284L0.609972 4.78345C0.219407 5.17413 0 5.70394 0 6.25637C0 6.80879 0.219407 7.3386 0.609972 7.72929L4.73497 11.8551C4.81234 11.9327 4.90424 11.9943 5.00541 12.0364C5.10659 12.0784 5.21505 12.1002 5.32463 12.1003C5.4342 12.1005 5.54273 12.0791 5.64402 12.0373C5.74532 11.9955 5.83738 11.9342 5.91497 11.8568C5.99256 11.7794 6.05415 11.6875 6.09623 11.5863C6.1383 11.4852 6.16004 11.3767 6.16019 11.2671C6.16035 11.1576 6.13892 11.049 6.09713 10.9477C6.05534 10.8464 5.99401 10.7544 5.91664 10.6768L2.32331 7.08595H15.8333C16.4963 7.08595 17.1322 7.34934 17.6011 7.81818C18.0699 8.28703 18.3333 8.92291 18.3333 9.58595V15.4193C18.3333 16.0823 18.0699 16.7182 17.6011 17.1871C17.1322 17.6559 16.4963 17.9193 15.8333 17.9193H4.16664C3.94563 17.9193 3.73366 18.0071 3.57738 18.1634C3.4211 18.3196 3.33331 18.5316 3.33331 18.7526C3.33331 18.9736 3.4211 19.1856 3.57738 19.3419C3.73366 19.4982 3.94563 19.586 4.16664 19.586H15.8333C16.938 19.5846 17.997 19.1452 18.7781 18.3641C19.5592 17.583 19.9987 16.524 20 15.4193V9.58595C20.0018 9.03791 19.8949 8.49496 19.6853 7.98856C19.4757 7.48217 19.1677 7.02241 18.7791 6.63595Z"
        // fill="#9747FF"
      />
    </svg>
  );
};

export default Back;
