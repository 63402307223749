/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import pusherInstance from "../../lib/clients/pusherInstance";
import { useRecoilState } from "recoil";
import surveyState from "../../pages/activities/survey/store";

type MsgType = {
	data: {
		id: number;
		title: string;
		description: string;
		type: number;
		type_name: string;
		overall_progress: string;
		questions: {
			id: number;
			text: string;
			description: string;
			options: {
				option_percentage: number;
				option_progress: number;
				id: number;
				is_correct: null;
				text: string;
			}[];
		}[];
	};
	eventType: string;
};

const useSurveyChannel = () => {
	const [, setData] = useRecoilState(surveyState);

	const channel_name = "private-admin-presenter";

	useEffect(() => {
		const pusher = pusherInstance();

		const surveyChannel = pusher.subscribe(channel_name);

		surveyChannel.bind("survey", (data: MsgType) => {
			setData({
				overall_percentage: Number.parseInt(data.data.overall_progress),
				data: data.data.questions.map((q) => ({
					description: q.description,
					id: q.id.toString(),
					question: q.text,
					options: q.options.map((o) => ({
						id: o.id.toString(),
						option: o.text,
						option_percentage: o.option_percentage,
						option_progress: o.option_progress,
					})),
				})),
			});
		});
	}, []);
};

export default useSurveyChannel;
