import { Button, useToast } from "@chakra-ui/react";
import Chip from "../../../../shared/Chip";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import surveyState from "../store";
import { useState } from "react";
import eventState from "../../../../store/eventState";
import { startContentSharing } from "../../../../lib/services/events";
import Bar from "../../../../components/Bar";

const Progress = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const data = useRecoilValue(surveyState);
	const [loading, setLoading] = useState(false);
	const eventStorage = useRecoilValue(eventState);

	const { t } = useTranslation();

	const handleShowResults = () => {
		const currentActivity =
			eventStorage.activities[eventStorage.activeContentIndex];
		setLoading(true);
		startContentSharing(currentActivity.id, "end")
			.then(() => {
				navigate("../show-result", { relative: "path" });
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Chip className="bg-violet-100 !rounded-[16px] p-4 flex items-center justify-between">
			<div className="flex items-center gap-5">
				<div className="flex items-center h-11 gap-[7px]">
					{Array.from(Array(46)).map((_, i) => (
						<Bar key={i} active={i <= (data.overall_percentage / 100) * 46} />
					))}
				</div>
				<p className="text-3xl font-bold text-violet-600">{data.overall_percentage}%</p>
			</div>
			<Button
				isDisabled={loading}
				// isDisabled={!(data.overall_percentage === 100)}
				variant={"primary"}
				className={`${
					data.overall_percentage === 100 ? "violet-btn" : "orange-btn"
				} btn-base w-[240px] !py-[18px]`}
				size="md"
				onClick={handleShowResults}
			>
				{data.overall_percentage === 100 ? t("Navigating to results") : t("Voting")}
			</Button>
		</Chip>
	);
};

export default Progress;
