import { Outlet } from "react-router";
import Header from "./Header/Header";
import usePresenceChannel from "../hooks/events/usePresenceChannel";

const Layout = () => {
	usePresenceChannel();

	return (
		<div className="flex flex-col min-h-screen bg-white">
			<Header />
			<Outlet />
		</div>
	);
};

export default Layout;
