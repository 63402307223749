/* eslint-disable no-dupe-keys */
export const italianStrings = {
	// admin
	//#region header
	ONAIR: "ONLINE",
	OFFLINE: "OFFLINE",
	USER: "UTENTI",
	ONLINE: "ONLINE",
	//#endregion header

	//#region modal
	"Are you sure want to stop sharing?":
		"Sei sicuro di voler iniziare a condividere?",
	"You’re going to start sharing": "Stai per condividere",
	Stop: "Stop",
	"Start Sharing": "Inizia a condividere",
	"Cancel Request": "Annulla",
	//#endregion modal

	//#region buzzer
	"Ask to answer": "Chiedi di rispondere",
	"Try Again": "Riprova",
	"Right answer!": "Risposta esatta!",
	"Wrong answer!": "Risposta errata!",
	"The winner is": "Il vincitore è",
	"Sorry, try again": "Ops, riprova",
	//#endregion buzzer

	//#region list
	"Display results": "Mostra risultati",
	"All users have finished!": "Tutti gli utenti hanno finito!",
	Open: "Apri",
	Start: "Inizia",
	"All element listed": "Tutti gli elementi sono ordinati",
	Statement: "Statement",
	"All element listed": "Tutti gli elementi sono ordinati",
	Save: "Save",
	"Drag and list element": "Trascina e ordina gli elementi",
	"Well done": "Well done",
	"Module is about to be completed by some users. Wait for everyone to finish":
		"Il modulo è stato completato da alcuni utenti. Attendi che tutti completino il modulo.",

	//#endregion list

	//#region quiz
	"Back to dashboard": "Torna alla dashboard",
	TRUE: "VERO",
	FALSE: "FALSO",
	"All users have voted!": "Tutti hanno votato",
	"Correct answer!": "Risposta corretta!",
	"Wrong answer!": "Risposta Errata",
	Send: "Invia",
	True: "VERO",
	False: "FALSO",
	"The quiz is about to be completed by some users. Wait for everyone to finish":
		"Il modulo è stato completato da alcuni utenti. Attendi che tutti completino il modulo.",

	//#endregion quiz

	//#region slideshow
	LIKES: "MI PIACE",
	DISLIKE: "NON MI PIACE",
	"Top liked and disliked slides": "Slide più votate",
	USED: "MOSTRATA",
	"ONLINE NOW": "ONLINE ADESSO",
	NEXT: "PROSSIMO",
	"NOT USED": "DA MOSTRARE",
	Prev: "Indietro",
	Next: "Avanti",
	"Presentation ended!": "Presentazione finita!",
	"Just few seconds before displaying all results":
		"Alcuni secondi prima di mostrare i risultati",

	//#endregion slideshow

	//#region survey
	"Navigating to results": "Vai ai risultati",
	Voting: "Stanno votando",
	Selected: "Selezionato",
	Select: "Seleziona",
	"The survey is about to be completed by some users. Wait for everyone to finish":
		"Il modulo è stato completato da alcuni utenti. Attendi che tutti completino il modulo.",
	//#endregion survey

	//#region components
	"You’re not sharing content": "Non stai condividendo!",
	"I'm just looking to warm up": "Ok, voglio solo scaldarmi",
	Playlist: "Agenda",
	"Shared content": "Moduli visti",
	"All content": "Tutti i moduli",
	votes: "voti",
	Welcome: "Benvenuti!",
	"Choose your avatar": "Scegli il tuo avatar",
	"Event is going to start": "L'evento inizierà a breve",
	"Emerging technologies in healthcare": "ANMCO",
	"Be patient as the moderator commences the session":
		"Tra poco il moderatore avvierà la sessione",
	Join: "Partecipa!",
	"Please fill fields": "Completa i campi",
	"First Name": "Nome",
	"Last name": "Cognome",
	Login: "Login",
	"Welcome Back": "Bentornato",
	"Let's continue from where we started":
		"Riprendiamo da dove avevamo lasciato",
	"Account created": "Account creato",
	"We've created your account for you": "Abbiamo creato il tuo account",
	"Something went wrong": "Ops, qualcosa è andato storto :(",
	"Please full all inputs and try again": "Completa tutti i campi e riprova!",
	"Please try again": "Prova di nuovo",
	//#endregion components

	"Rising Project": "Maggio 2024",
	"Ready to go": "Pronti a iniziare",
	"Ready To Go": "Tutti hanno votato",
	"You’re online": "Sei online",
	"Admin is going to start sharing content asap":
		"Tra poco il moderatore avvierà la sessione",
	"Congrats you have found a secret place, unfortunately you have to go back":
		"Complimenti, hai trovato un angolo nascoto, ora torniamo indietro",
	Home: "Home",
	"Access to this page is restricted": "L'accesso a questa pagina è riservato",
	"Please check with the site admin if you believe this is a mistake":
		"Ti preghiamo di verificare con l'amministratore se pensi che questo sia un errore",
	"Finish Slides": "Chiudi presentazione",

	"This Action Can't Be Reverted": "Questa azione è definitiva",
	"Are You Sure, You Want To Reset Results For This Activity ?":
		"Sei sicuro di voler fare di nuovo questo modulo?",
	Close: "Annulla",
	Sure: "S', sono sicuro",
	"Content Resat Successfully": "Contenuti cancellati correttamente",
	All: "Tutti",
	score: "Posizione",
	scale: "Punteggio",
	"Showing results sorted by": "Ordina i risultati per",
};
