import Chip from "../../../../shared/Chip";

const UserChip = ({
	onClick,
	img,
	username,
	loading,
}: {
	onClick: () => void;
	username: string;
	img: string;
	loading: boolean;
}) => {
	return (
		<Chip
			onClick={onClick}
			className={`border-[6px] border-violet-300 bg-violet-100 hover:bg-violet-300 flex items-center gap-[10px] transition-colors duration-300 ${
				loading ? "cursor-not-allowed" : "cursor-pointer"
			}`}
		>
			<img src={img} alt="user" className="w-12 h-12" />
			<p className="text-2xl leading-[28px] font-bold text-dolphin-700 ">
				{username}
			</p>
		</Chip>
	);
};

export default UserChip;
