import { useRecoilState, useRecoilValue } from "recoil";
import Chip from "../../../../shared/Chip";
import eventState, { MakeYourListActivity } from "../../../../store/eventState";
import { useParams } from "react-router-dom";
import listStore, { listDefault } from "../store";
import { useEffect, useState } from "react";
import ListItemResultCard from "./ListItemResultCard";
import ListResultsControl from "./ListResultsControl";
import currentlyShow, { CurrentlyShowTypes } from "./currentlyShow.store";
import { FaSpinner } from "react-icons/fa";
import currentlySort from "./currentlySort";

type ListResultsType = {
	option_id: number;
	text: string;
	score: { all: number};
	scale: { all: number};
}[];

// const ascSort = <T, >(a: T, b: T) => +b - +a;
// const desSort = <T, >(a: T, b: T) => +b + +a;

const ListResults = () => {
	const [listStorage, setListStorage] = useRecoilState(listStore);
	const [current, setCurrent] = useRecoilState(currentlyShow);
	const eventStorage = useRecoilValue(eventState);
	const [listResults, setListResults] = useState<ListResultsType>([]);
	const [currentSort, setCurrentSort] = useRecoilState(currentlySort);

	const [loading, setLoading] = useState(false);

	const { currentIndex } = useParams();

	const index = +(currentIndex as string);
	const currentEvent = eventStorage.activities[index] as MakeYourListActivity;

	function sortByPropScore(
		list: ListResultsType,
		prop: CurrentlyShowTypes
	): ListResultsType {
		// console.log(currentSort, prop);
		return list.slice().sort((a, b) => +b[currentSort][prop] - +a[currentSort][prop]);
	}

	useEffect(() => {
		return () => {
			setListStorage(listDefault);
			setCurrent("all");
			setCurrentSort("score");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setListResults(sortByPropScore(listStorage.data, current));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [current, currentSort]);

	return (
		<div className="m-5 mt-0 h-[calc(100dvh-106px-20px)] flex flex-col gap-4">
			<Chip className="bg-violet-100 !p-5 rounded flex flex-col gap-5 ">
				<div className="text-dolphin-700">
					<h3 className="text-[24px] font-bold">{currentEvent.title}</h3>
					<p
						className="text-lg mt-[10px]"
						dangerouslySetInnerHTML={{ __html: currentEvent.description }}
					/>
				</div>
			</Chip>
			<Chip
				className={`relative ${
					loading && ""
				} overflow-y-scroll custom-scroll bg-violet-100 !p-5 rounded flex flex-col gap-5 flex-1`}
			>
				{listResults.map((item, j) => (
					<ListItemResultCard
						avg={item.scale[current]}
						content={item.text}
						index={j + 1}
						percentage={item.score[current]}
						isHighest={j === 0}
						key={j}
					/>
				))}
				{loading && (
					<div className="fixed top-0 left-0 w-full h-full glassmor_it flex items-center justify-center z-20">
						<FaSpinner
							className="text-violet-700 animate-spin duration-300"
							size={40}
						/>
					</div>
				)}
			</Chip>
			<ListResultsControl loading={loading} setLoading={setLoading} />
		</div>
	);
};

export default ListResults;
