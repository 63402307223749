/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import pusherInstance from "../../lib/clients/pusherInstance";
import { useRecoilState } from "recoil";
import quizStore from "../../pages/activities/quiz/store";

export type Actions =
	| "start"
	| "show"
	| "end"
	| "show_results"
	| "prev"
	| "next";

type normalMsg = {
	id: number;
	title: string;
	description: string;
	type: number;
	type_name: string;
	overall_progress: string;
	questions: {
		id: number;
		text: string;
		description: string;
		results: {
			false: number;
			true: number;
			true_count: number;
			false_count: number;
			correct_answer: boolean;
		};
	}[];
};

type MsgType = {
	data: normalMsg;
	eventType: string;
};

const useQuizChannel = () => {
	const [, setQuizState] = useRecoilState(quizStore);

	const channel_name = "private-admin-presenter";

	useEffect(() => {
		const pusher = pusherInstance();

		const quizChannel = pusher.subscribe(channel_name);

		quizChannel.bind("true/false", (data: MsgType) => {
			const cData = data.data;
			setQuizState((prev) => ({
				...prev,
				overall_progress: cData.overall_progress,
				data: cData.questions.map((item) => ({
					description: item.description,
					text: item.text,
					results: {
						false: item.results.false,
						true: item.results.true,
						false_count: item.results.false_count,
						true_count: item.results.true_count,
						isTrue: item.results.correct_answer
					},
				})),
			}));
		});
	}, []);
};

export default useQuizChannel;
