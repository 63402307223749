import { Outlet, createBrowserRouter } from "react-router-dom";
import Layout from "../layout/Layout";

// ! Slideshow pages
import SlideShowResults from "../pages/activities/slideshow/slideShowResult/SlideShowResults";
import SlideShowPlayer from "../pages/activities/slideshow/SlideShowPlayer";
import SlideShowStart from "../pages/activities/slideshow/SlideShowStart";
import SlideShowEnd from "../pages/activities/slideshow/SlideShowEnd";

// ! Survey Pages
import SurveyObserver from "../pages/activities/survey/SurveyObserver";
import ShowResultSurvey from "../pages/activities/survey/ShowResult";
import SurveyStart from "../pages/activities/survey/SurveyStart";
import Results from "../pages/activities/survey/result/Results";

// ! Quiz Pages
import QuizObserver from "../pages/activities/quiz/QuizObserver/QuizObserver";
import QuizResults from "../pages/activities/quiz/QuizResults/QuizResults";
import QuizShowResults from "../pages/activities/quiz/QuizShowResults";
import QuizStart from "../pages/activities/quiz/QuizStart";

// ! Buzzer Pages
import BuzzerControl from "../pages/activities/buzzer/BuzzerControl/BuzzerControl";
import ShowResultBuzzer from "../pages/activities/buzzer/ShowResults";
import BuzzerWinner from "../pages/activities/buzzer/BuzzerWinner";
import BuzzerStart from "../pages/activities/buzzer/BuzzerStart";

// ! List Pages
import StartListActivity from "../pages/activities/list/StartListActivity";
import ListResults from "../pages/activities/list/listResult/ListResults";
import ListShowResults from "../pages/activities/list/ListShowResults";
import ListObserver from "../pages/activities/list/ListObserver";
import ListStart from "../pages/activities/list/ListStart";

// ! Game Pages
import GameShowResult from "../pages/activities/game/GameShowResult";
import GameResults from "../pages/activities/game/GameResults";
import WaitSubmit from "../pages/activities/game/WaitSubmit";
import GameStart from "../pages/activities/game/GameStart";

// ! Root Pages
import ErrorElement from "../components/ErrorElement";
import UnAuthorized from "../pages/403/UnAuthorized";
import NotFound from "../pages/404/NotFound";
import Index from "../pages/auth/Index";
import Home from "../pages/home/Home";


const useRouter = () => {
	const routes = createBrowserRouter([
		{
			path: "/token/:token",
			element: <Index />,
		},
		{
			element: <Layout />,
			path: "/",
			children: [
				{
					path: "",
					element: <Home />,
				},
				{
					path: "activity/:currentIndex",
					element: <Outlet />,
					errorElement: <ErrorElement />,
					children: [
						//#region slideshow
						// ! Slideshow routes
						{
							path: "slideshow",
							element: <Outlet />,
							children: [
								{
									path: "",
									element: <SlideShowStart />,
								},
								{
									path: "player",
									element: <SlideShowPlayer />,
								},
								{
									path: "end",
									element: <SlideShowEnd />,
								},
								{
									path: "result",
									element: <SlideShowResults />,
								},
							],
						},
						//#endregion slideshow

						//#region survey
						// ! Survey routes
						{
							path: "survey",
							element: <Outlet />,
							children: [
								{
									path: "",
									element: <SurveyStart />,
								},
								{
									path: "observer",
									element: <SurveyObserver />,
								},
								{
									path: "show-result",
									element: <ShowResultSurvey />,
								},
								{
									path: "results",
									element: <Results />,
								},
							],
						},
						//#endregion survey

						//#region buzzer
						// ! Buzzer routes
						{
							path: "buzzer",
							element: <Outlet />,
							children: [
								{
									path: "",
									element: <BuzzerStart />,
								},
								{
									path: "observer",
									element: <BuzzerControl />,
								},
								{
									path: "show-result",
									element: <ShowResultBuzzer />,
								},
								{
									path: "results",
									element: <BuzzerWinner />,
								},
							],
						},
						//#endregion buzzer

						//#region quiz
						// ! Quiz routes
						{
							path: "quiz",
							element: <Outlet />,
							children: [
								{
									path: "",
									element: <QuizStart />,
								},
								{
									path: "observer",
									element: <QuizObserver />,
								},
								{
									path: "show-result",
									element: <QuizShowResults />,
								},
								{
									path: "results",
									element: <QuizResults />,
								},
							],
						},
						//#endregion quiz

						//#region list
						// ! List routes
						{
							path: "list",
							element: <Outlet />,
							children: [
								{
									path: "",
									element: <ListStart />,
								},
								{
									path: "start",
									element: <StartListActivity />,
								},
								{
									path: "observer",
									element: <ListObserver />,
								},
								{
									path: "show-result",
									element: <ListShowResults />,
								},
								{
									path: "results",
									element: <ListResults />,
								},
							],
						},
						//#endregion list
					
						//#region game
						// ! Start Game
						{
							path: "game",
							element: <Outlet />,
							children: [
								{
									path: "",
									element: <GameStart />,
								},
								{
									path: "wait-submit",
									element: <WaitSubmit />,
								},
								{
									path: "show-result",
									element: <GameShowResult />,
								},
								{
									path: "results",
									element: <GameResults />,
								},
							],
						},
						//#endregion game
					],
				},
			],
		},
		{
			path: "/un-authorized-access",
			element: <UnAuthorized />,
		},
		{
			path: "*",
			element: <NotFound />,
		},
	]);

	return routes;
};

export default useRouter;
