import { useRecoilValue } from "recoil";
import Chip from "../../../../shared/Chip";
import BuzzerOption from "./BuzzerOption";
// import { useNavigate } from "react-router-dom";
import userSelection from "./store";

type Props = {
	id: string;
	question: string;
	description: string;
	options: {
		id: string;
		option: string;
		isTheRightAnswer: boolean;
	}[];
	index: number;
	total: number;
};

const BuzzerSlide = ({
	description,
	id,
	question,
	options,
	index,
	total,
}: Props) => {
	const uss = useRecoilValue(userSelection);

	return (
		<Chip
			key={id}
			className="bg-violet-100 rounded !p-5 flex flex-col gap-[30px] flex-1 h-full"
		>
			<Chip className="bg-white text-dark-mid text-sm !rounded-[60px] px-4 py-[6px] w-fit">
				{index}/{total}
			</Chip>
			<div className="text-dolphin-700">
				<h2 className="text-3xl font-bold leading-9">{question}</h2>
				<p
					className="mt-[10px] text-lg leading-[21px]"
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			</div>
			<div className="flex flex-col gap-5">
				{options.map((o, j) => {
					return (
						<BuzzerOption
							selected={uss.userSelected === +o.id}
							key={o.id}
							index={j}
							id={o.id}
							isTheRightAnswer={uss.selectionState}
							option={o.option}
						/>
					);
				})}
			</div>
		</Chip>
	);
};

export default BuzzerSlide;
