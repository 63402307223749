import Counter from "../components/activitiesControls/Counter";

export const mapExerciseToIndicator = (exerciseId: number) => {
  if (exerciseId === 1) {
    return undefined;
  } else if (exerciseId === 2) {
    return undefined;
  } else if (exerciseId === 3) {
    return Counter;
  } else if (exerciseId === 4) {
    return undefined;
  } else if (exerciseId === 5) {
    return Counter;
  }

  return undefined;
};
