const Bar = ({ active }: { active: boolean }) => {
	return (
		<div
			className={`self-stretch w-2 rounded-[2px] transition-all duration-300 ${
				active ? "bg-violet-600" : "bg-violet-300"
			}`}
		/>
	);
};

export default Bar;