import { Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Buzzer from "../../../icons/Buzzer";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import eventState, { BuzzerActivity } from "../../../store/eventState";
import { startContentSharing } from "../../../lib/services/events";
import { useState } from "react";
import buzzerStore from "./store/buzzerStore";

const BuzzerStart = () => {
	const navigate = useNavigate();
	const toast = useToast();

	const eventStorage = useRecoilValue(eventState);
	const [, setBuzzerStorage] = useRecoilState(buzzerStore);

	const currentEvent = eventStorage.activities[
		eventStorage.activeContentIndex
	] as BuzzerActivity;

	const [loading, setLoading] = useState(false);

	const { t } = useTranslation();

	const handleStartContentShare = () => {
		setLoading(true);
		startContentSharing(currentEvent.id, "start")
			.then(() => {
				navigate("observer");
				setBuzzerStorage({
					currentIndex: 0,
					questions: currentEvent.questions.map((q) => ({
						description: q.description,
						id: q.id.toString(),
						question: q.text,
						options: q.buzzer_options.map((op) => ({
							id: op.id.toString(),
							option: op.text,
							isTheRightAnswer: false,
						})),
					})),
					winner: {
						first_name: "",
						id: 0,
						image: "",
						last_name: "",
					},
					// questions: currentEvent.questions.map
				});
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className="bg-pink-600 flex-1 m-5 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="scale-[3] stroke-white">
				<Buzzer />
			</p>
			<div className="text-white max-w-[820px] text-3xl text-center">
				<h3>{currentEvent.title}</h3>
				<p
					className="font-bold"
					dangerouslySetInnerHTML={{ __html: currentEvent.description }}
				/>
			</div>
			<Button
				variant={"outline"}
				className="outline-btn w-[400px]  !transition-all !duration-300 font-bold !text-[24px] !leading-[24px]"
				size={"md"}
				isDisabled={!eventStorage.live || loading}
				onClick={() => handleStartContentShare()}
			>
				{t("Start")}
			</Button>
		</div>
	);
};

export default BuzzerStart;
