import { Button, useToast } from "@chakra-ui/react";
import Result from "./Result";
import { useRecoilState } from "recoil";
import resultCarousel from "./store";
import { useNavigate } from "react-router-dom";
import eventState from "../../../../store/eventState";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { goBack, startContentSharing } from "../../../../lib/services/events";

const Results = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [eState, setEventState] = useRecoilState(eventState);
	const [carouselData, setCarouselData] = useRecoilState(resultCarousel);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		return () => {
			setEventState((prev) => ({
				...prev,
				activity: undefined,
				activityTitle: "",
			}));
			setCarouselData({
				currentIndex: 0,
				total: 0,
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const exitActivity = () => {
		setLoading(true);
		goBack()
			.then(() => {
				setEventState((prev) => ({
					...prev,
					activity: undefined,
				}));
				navigate("/");
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: "",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => setLoading(false));
	};

	const handleChangeSlide = (
		subIndex: number,
		prevIndex: number,
		action: "next" | "prev"
	) => {
		setLoading(true);
		setCarouselData((prev) => ({
			...prev,
			currentIndex: subIndex,
		}));
		startContentSharing(eState.activities[eState.activeContentIndex].id, action)
			.then(() => {
				// setResultsStore(data.data.data.results);
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
				setCarouselData((prev) => ({
					...prev,
					currentIndex: prevIndex,
				}));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className="p-5 pt-0 flex-1 flex flex-col gap-5 max-h-[calc(100vh-106px)] overflow-hidden justify-between">
			<Result />
			<div className="flex items-center justify-end gap-5">
				<Button
					className="orange-btn base-btn !px-[60px] !py-[18px] text-[24px] font-bold"
					size="md"
					isDisabled={carouselData.currentIndex === 0 || loading}
					onClick={() => {
						if (carouselData.currentIndex !== 0) {
							handleChangeSlide(
								carouselData.currentIndex - 1,
								carouselData.currentIndex,
								"prev"
							);
						}
					}}
				>
					{t("Prev")}
				</Button>
				<Button
					className={`violet-btn base-btn !px-[60px] !py-[18px] text-[24px] font-bold ${
						carouselData.currentIndex === carouselData.total - 1
							? "w-[197px]"
							: "w-[169px]"
					}`}
					size="md"
					isDisabled={loading}
					onClick={() => {
						if (carouselData.currentIndex < carouselData.total - 1) {
							handleChangeSlide(
								carouselData.currentIndex + 1,
								carouselData.currentIndex,
								"next"
							);
						} else {
							exitActivity();
						}
					}}
				>
					{carouselData.currentIndex === carouselData.total - 1
						? t("Back to dashboard")
						: t("Next")}
				</Button>
			</div>
		</div>
	);
};

export default Results;
