import { AtomEffect, atom } from "recoil";

type eventKeyStoreType = {
  key: string;
};

const localStorageEffect =
  (key: string): AtomEffect<eventKeyStoreType> =>
    ({ setSelf, onSet }) => {
      const savedValue = localStorage.getItem(key);
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue, _, isReset) => {
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue));
      });
    };

export const eventKeyStoreDefault = {
  key: "",
};

const eventKeyStore = atom<eventKeyStoreType>({
  key: "eventKeyStore",
  default: eventKeyStoreDefault,
  effects: [localStorageEffect("credentials")],
});

export default eventKeyStore;
