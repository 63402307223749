import { useEffect, useState } from "react";
import Quiz from "../../../icons/Quiz";
import { Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import quizStore from "./store";
import { useTranslation } from "react-i18next";
import eventState, { QuizActivity } from "../../../store/eventState";
import { startContentSharing } from "../../../lib/services/events";

const QuizStart = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const toast = useToast();

	const [loading, setLoading] = useState(false);

	const [, setQuizStore] = useRecoilState(quizStore);
	const eventStorage = useRecoilValue(eventState);

	const currentEvent = eventStorage.activities[
		eventStorage.activeContentIndex
	] as QuizActivity;

	useEffect(() => {
		setQuizStore({
			data: currentEvent.questions.map((q) => ({
				description: q.description,
				text: q.text,
				results: {
					false: 0,
					true: 0,
					false_count: 0,
					true_count: 0,
					isTrue: false,
				},
			})),
			overall_progress: "",
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleStartContentShare = () => {
		setLoading(true);
		startContentSharing(currentEvent.id, "start")
			.then(() => {
				navigate("observer");
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className="bg-blaze-600 flex-1 m-5 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="scale-[2.2] fill-white">
				<Quiz />
			</p>
			<div className="text-white max-w-[820px] text-3xl text-center">
				<h3>{currentEvent.title}</h3>
				<p
					className="font-bold"
					dangerouslySetInnerHTML={{ __html: currentEvent.description }}
				/>
			</div>
			<Button
				variant={"outline"}
				className="outline-btn w-[400px]  !transition-all !duration-300 font-bold !text-[24px] !leading-[24px]"
				size={"md"}
				onClick={handleStartContentShare}
				isDisabled={loading}
			>
				{t("Start")}
			</Button>
		</div>
	);
};

export default QuizStart;
