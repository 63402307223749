import { atom } from "recoil";

export type OptionSurveyStoreType = {
	id: string;
	option: string;
	option_percentage: number;
	option_progress: number;
};

type surveyStoreType = {
	overall_percentage: number;
	data: {
		id: string;
		question: string;
		description: string;
		options: OptionSurveyStoreType[];
	}[];
};

export const defaultSurveyState: surveyStoreType = {
	overall_percentage: 0,
	data: [],
};

const surveyState = atom<surveyStoreType>({
	key: "surveyStore",
	default: defaultSurveyState,
});

export default surveyState;
