import { Button } from "@chakra-ui/react";
import Chip from "../../../../shared/Chip";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { startContentSharing } from "../../../../lib/services/events";
import { useRecoilValue } from "recoil";
import eventState from "../../../../store/eventState";
import { useState } from "react";
import Bar from "../../../../components/Bar";

const Progress = ({ percent }: { percent: number }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [loading, setLoading] = useState(false);

	const eventStorage = useRecoilValue(eventState);
	const currentActivity =
		eventStorage.activities[eventStorage.activeContentIndex];

	const handleShowResults = () => {
		setLoading(true);
		startContentSharing(currentActivity.id, "show_viewer")
			.then(() => navigate("../show-result", { relative: "path" }))
			.finally(() => setLoading(false));
	};

	return (
		<Chip className="bg-violet-100 !rounded-[16px] p-4 flex items-center justify-between">
			<div className="flex items-center gap-5">
				<div className="flex items-center h-11 gap-[7px]">
					{Array.from(Array(46)).map((_, i) => (
						<Bar key={i} active={i <= (percent / 100) * 46} />
					))}
				</div>
				<p className="text-3xl font-bold text-violet-600">{percent}%</p>
			</div>
			<Button
				// isDisabled={!(percent >= 100) || loading}
				isLoading={loading}
				variant={"primary"}
				className={`${
					percent === 100 ? "violet-btn" : "orange-btn"
				} btn-base w-[240px] !py-[18px]`}
				size="md"
				onClick={() => handleShowResults()}
			>
				{percent === 100 ? t("Navigating to results") : t("Voting")}
			</Button>
		</Chip>
	);
};

export default Progress;
