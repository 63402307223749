import { useState } from "react";
import Game from "../../../icons/Game";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import eventState, { GameType } from "../../../store/eventState";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { startContentSharing } from "../../../lib/services/events";
import resultStore from "./store/results";

const GameShowResult = () => {
	const navigate = useNavigate();

	const eventStorage = useRecoilValue(eventState);
	const [, setResults] = useRecoilState(resultStore);

	const [loading, setLoading] = useState(false);

	const currentEvent = eventStorage.activities[
		eventStorage.activeContentIndex
	] as GameType;

	const { t } = useTranslation();

	const handleEvent = () => {
		setLoading(true);
		startContentSharing(currentEvent.id, "show_results")
			.then((data) => {
				const results = data.data.data.results;
				setResults({
					overall_progress: results.overall_progress as string,
					score: (results.score as { text: string; percentage: string }[]).map(
						(item) => ({ ...item })
					),
				});
				// console.log(results);
			})
			.finally(() => {
				setTimeout(() => {
					navigate("../results", { replace: false });
					setLoading(false);
				}, 1000);
			});
	};

	return (
		<div className="bg-bile-600 flex-1 m-5 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="scale-[3] fill-white">
				<Game />
			</p>
			<div className="text-white max-w-[820px] text-3xl text-center">
				<h3>{t("Ready To Go")}!</h3>
			</div>
			<Button
				variant={"outline"}
				className="disabled:cursor-not-allowed outline-btn w-[400px]  !transition-all !duration-300 font-bold !text-[24px] !leading-[24px]"
				size={"md"}
				isDisabled={!eventStorage.live || loading}
				onClick={() => handleEvent()}
			>
				{t("Display results")}
			</Button>
		</div>
	);
};

export default GameShowResult;
