import ResultCard from "./ResultCard";
import Chip from "../../../../shared/Chip";
import { Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import quizStore from "../store";
import { useState } from "react";
import { goBack } from "../../../../lib/services/events";
import eventState from "../../../../store/eventState";
import { useTranslation } from "react-i18next";

const QuizResults = () => {
	const quiz = useRecoilValue(quizStore);

	const navigate = useNavigate();
	const toast = useToast();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [, setState] = useRecoilState(eventState);

	console.log(quiz);
	

	const exitActivity = () => {
		setLoading(true);
		goBack()
			.then(() => {
				setState((prev) => ({
					...prev,
					activities: prev.activities.map((item, j) =>
						prev.activeContentIndex === j ? { ...item, state: "played" } : item
					),
					activity: undefined,
				}));
				navigate("/");
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: "",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className="p-5 flex flex-col gap-5">
			<Chip className="p-[30px] rounded h-[calc(100vh-106px-67px-60px)]">
				<div className="grid grid-cols-12 gap-5 overflow-y-scroll custom-scroll h-full">
					{quiz.data.map((q, i) => (
						<div
							key={i}
							className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 self-stretch row-span-1"
						>
							<ResultCard
								key={i}
								index={i}
								description={q.description}
								title={q.text}
								falseCount={q.results.false_count}
								trueCount={q.results.true_count}
								isTrue={q.results.isTrue}
							/>
						</div>
					))}
				</div>
			</Chip>
			<Button
				variant={"primary"}
				size="md"
				isDisabled={loading}
				className="violet-btn btn-base self-end"
				onClick={exitActivity}
			>
				{t("Back to dashboard")}
			</Button>
		</div>
	);
};

export default QuizResults;
