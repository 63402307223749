import { useRecoilState, useRecoilValue } from "recoil";
import Chip from "../../../shared/Chip";
import ListItemResult from "./ListItemResults";
import resultStore from "./store/results";
import { useEffect } from "react";
import eventState from "../../../store/eventState";

const GameResults = () => {
	const listStorage = useRecoilValue(resultStore);
	const [, setEventStorage] = useRecoilState(eventState);

	useEffect(() => {
		// console.log(listStorage)
		return () => {
			setEventStorage((prev) => ({
				...prev,
				activeContentIndex: -1,
				activity: undefined,
				activityTitle: "",
				activities: prev.activities.map((item, i) => ({
					...item,
					state: i === prev.activeContentIndex ? "played" : "queued",
				})),
			}));
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Chip className="bg-violet-100 !p-5 m-5 rounded flex flex-col gap-5 h-[calc(100vh-106px-40px)]">
			<div className="flex-1 flex flex-col gap-5 overflow-y-auto">
				{listStorage.score &&
					listStorage.score.length > 0 &&
					listStorage.score.map((item, j) => (
						<ListItemResult
							key={j}
							isRight={j === 0}
							index={j + 1}
							statement={item.text}
							withAnswer={true}
							percent={Number.parseInt(item.percentage)}
						/>
					))}
			</div>
		</Chip>
	);
};

export default GameResults;
