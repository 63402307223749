import ControlButton from "./ControlButton";
import ToggleButton from "../../../../shared/ToggleButton";
import { useRecoilState } from "recoil";
import listStore, { listDefault } from "../store";
import { useNavigate } from "react-router-dom";
import eventState, { MakeYourListActivity } from "../../../../store/eventState";
import { Dispatch, SetStateAction } from "react";
import { goBack, startContentSharing } from "../../../../lib/services/events";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import currentlyShow, { CurrentlyShowTypes } from "./currentlyShow.store";
import currentlySort, { CurrentlySortType } from "./currentlySort";

const ListResultsControl = ({
	loading,
	setLoading,
}: {
	setLoading: Dispatch<SetStateAction<boolean>>;
	loading: boolean;
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const toast = useToast();

	const [, setListStorage] = useRecoilState(listStore);
	const [current, setCurrent] = useRecoilState(currentlyShow);
	const [currentSort, setCurrentSort] = useRecoilState(currentlySort);
	const [event, setState] = useRecoilState(eventState);

	const currentEvent = event.activities[
		event.activeContentIndex
	] as MakeYourListActivity;

	const exitActivity = () => {
		setLoading(true);
		goBack()
			.then(() => {
				setState((prev) => ({
					...prev,
					activity: undefined,
				}));
				navigate("/");
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: "",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => {
				setListStorage(listDefault);
				setLoading(false);
			});
	};

	const handleChangeCurrentlyShow = (
		curr: CurrentlyShowTypes,
		sort: CurrentlySortType
	) => {
		setLoading(true);
		startContentSharing(currentEvent.id, `show_${curr}_${sort}`)
			.then(() => {
				setCurrent(curr);
				setCurrentSort(sort);
			})
			.catch(() =>
				toast({
					title: t("Something went wrong") + ".",
					description: "",
					status: "error",
					duration: 9000,
					isClosable: true,
				})
			)
			.finally(() => setLoading(false));
	};

	return (
		<div className="flex items-center justify-between">
			<div className="flex items-center gap-10">
				<div className="flex items-center gap-4">
					<span>Sort: </span>
					<div className="flex items-center gap-5">
						<ToggleButton
							active={currentSort === "score"}
							onClick={() => handleChangeCurrentlyShow(current, "score")}
							className="px-8 py-4 leading-4"
							disabled={loading}
						>
							{t("score")}
						</ToggleButton>
						<ToggleButton
							active={currentSort === "scale"}
							onClick={() => handleChangeCurrentlyShow(current, "scale")}
							className="px-8 py-4 leading-4"
							disabled={loading}
						>
							{t("scale")}
						</ToggleButton>
					</div>
				</div>
			</div>
			<ControlButton onClick={exitActivity} loading={loading}>
				{t("Back to dashboard")}
			</ControlButton>
		</div>
	);
};

export default ListResultsControl;
