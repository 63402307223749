import Like from "../../../../icons/Like";
import DisLike from "../../../../icons/DisLike";
import { useTranslation } from "react-i18next";

type Props = {
	count: number;
	imgUrl: string;
	title: string;
	liked: boolean;
};

const ResultCard = ({ count, imgUrl, liked, title }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="flex items-center gap-5">
			<div
				className={`${
					liked ? "bg-violet-600" : "bg-[#E85B48]"
				} self-stretch flex flex-col items-center gap-[25px] rounded p-5 w-[100px] min-w-[100px] text-center`}
			>
				<div className="flex flex-col items-center text-white fill-white stroke-white">
					<p className="text-[42px] font-bold leading-[50px]">{count}</p>
					<p>{liked ? t("LIKES") : t("DISLIKE")}</p>
				</div>
				<p className="scale-[2.2] flex items-center justify-center">
					{liked ? <Like /> : <DisLike />}
				</p>
			</div>
			<img src={imgUrl} alt={title} className="h-[166px] rounded" />
			<p className="text-lg text-dolphin-700 leading-[21px] line-clamp-3">
				{title}
			</p>
		</div>
	);
};

export default ResultCard;
