import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { useEffect, useRef } from "react";
import buzzerStore from "../store/buzzerStore";
import BuzzerSlide from "./BuzzerSlide";
import { useRecoilState } from "recoil";
import UsersObserver from "./UsersObserver";
import useBuzzerChannel from "../../../../hooks/events/useBuzzerChannel";

const BuzzerControl = () => {
	useBuzzerChannel();
	const swiperRef = useRef<SwiperClass | null>(null);
	// const navigate = useNavigate();

	const [buzzerQues] = useRecoilState(buzzerStore);
	
	useEffect(() => {
		if (swiperRef.current) {
			swiperRef?.current?.slideTo(buzzerQues.currentIndex);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buzzerQues.currentIndex]);

	return (
		<div className="flex mb-5 mt-4 mx-5 max-h-[calc(100vh-40px)]">
			<Swiper
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper;
				}}
				spaceBetween={50}
				slidesPerView={1}
				autoplay={false}
				draggable={false}
				allowTouchMove={false}
			>
				{buzzerQues.questions.map((b, i) => (
					<SwiperSlide key={i}>
						<BuzzerSlide
							index={i + 1}
							total={buzzerQues.questions.length}
							description={b.description}
							id={b.id}
							question={b.question}
							options={b.options}
						/>
					</SwiperSlide>
				))}
			</Swiper>
			<div className="w-[400px]  flex-shrink-0 pl-5 h-[calc(100vh-106px-40px)]">
				<UsersObserver />
			</div>
		</div>
	);
};

export default BuzzerControl;
