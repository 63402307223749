import { useFullscreen } from "../../hooks/useFullScreenMantine";
import Chip from "../../shared/Chip";
import { MdFullscreen, MdFullscreenExit } from "react-icons/md";
import { FaSignOutAlt } from "react-icons/fa";
import { useRecoilState } from "recoil";
// import eventKeyStore, { eventKeyStoreDefault } from "../../store/eventKey";
import eventState, { defaultEventState } from "../../store/eventState";
import displayType from "../../store/displayView";
import userLiveStore, { userLiveStoreDefault } from "../../store/userLiveStore";
import { useNavigate } from "react-router-dom";

const ToggleFullScreen = () => {
	const { fullscreen, toggle } = useFullscreen();

	const navigate = useNavigate();

	// const [, setKey] = useRecoilState(eventKeyStore);
	const [, setEventState] = useRecoilState(eventState);
	const [, setDisplayType] = useRecoilState(displayType);
	const [, setUserLive] = useRecoilState(userLiveStore);

	const handleLogOut = () => {
		// setKey(eventKeyStoreDefault);
		setEventState(defaultEventState);
		setDisplayType("grid");
		setUserLive(userLiveStoreDefault);
		navigate("/");
	};

	return (
		<Chip className="flex items-center justify-center self-stretch gap-2">
			<button
				className={`p-[14px] rounded transition-all duration-300 ${
					fullscreen ? "violet-btn !text-white" : "bg-white !text-violet-600"
				} disabled:bg-gray-50 disabled:!text-gray-700`}
				// disabled={!state.live}
				onClick={toggle}
			>
				{fullscreen ? (
					<MdFullscreenExit size={22} />
				) : (
					<MdFullscreen size={22} />
				)}
			</button>
			<button
				className={`p-[14px] rounded transition-all duration-300 bg-white text-violet-600 hover:bg-violet-600 hover:text-white disabled:bg-gray-50 disabled:!text-gray-700`}
				// disabled={!state.live}
				onClick={handleLogOut}
			>
				<FaSignOutAlt size={22} />
			</button>
		</Chip>
	);
};

export default ToggleFullScreen;
