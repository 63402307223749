import Chip from "../../../../shared/Chip";
import Circle from "../../../../shared/Circle";
import cStyles from "./module/styles.module.css";

type Props = {
	id: string;
	option: string;
	isTheRightAnswer: boolean;
	index: number;
	selected: boolean;
};

const BuzzerOption = ({ isTheRightAnswer, option, index, selected }: Props) => {
	return (
		<Chip
			className={`rounded !p-4 ${
				selected
					? isTheRightAnswer
						? cStyles.right
						: cStyles.wrong
					: cStyles.unChecked
			} flex items-center gap-5`}
		>
			<Circle
				className={`text-3xl font-bold ${
					selected
						? isTheRightAnswer
							? "bg-white text-green-600"
							: "bg-white text-orange-600"
						: "bg-violet-600 text-white"
				} w-[62px] h-[62px] flex items-center justify-center`}
			>
				{index === 0 ? "A" : index === 1 ? "B" : index === 2 ? "C" : "D"}
			</Circle>
			<p className="text-2xl leading-7">{option}</p>
		</Chip>
	);
};

export default BuzzerOption;
