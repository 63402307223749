import Progress from "./Progress";
import Chip from "../../../shared/Chip";
import ListItem from "./ListItem";
import { useRecoilValue } from "recoil";
import eventState, { MakeYourListActivity } from "../../../store/eventState";
import useListChannel from "../../../hooks/events/useListChannel";
import listStore from "./store";

const ListObserver = () => {
	useListChannel();

	const eventStorage = useRecoilValue(eventState);
	const listStorage = useRecoilValue(listStore);

	const currentEvent = eventStorage.activities[
		eventStorage.activeContentIndex
	] as MakeYourListActivity;

	const percentage = Math.trunc(Number.parseInt(listStorage.overall_progress));

	return (
		<div className="p-5">
			<Progress percent={percentage || 0} />
			<Chip className="bg-violet-100 !p-5 mt-5 rounded flex flex-col gap-5 h-[calc(100vh-106px-95px-60px)]">
				<div className="text-dolphin-700">
					<h3 className="text-[24px] font-bold">{currentEvent.title}</h3>
					<p
						className="text-lg mt-[10px]"
						dangerouslySetInnerHTML={{ __html: currentEvent.description }}
					/>
				</div>
				<div className="flex-1 flex flex-col gap-5 overflow-y-auto custom-scroll">
					{currentEvent.options.map((o, i) => (
						<ListItem index={i} statement={o.text} />
					))}
				</div>
			</Chip>
		</div>
	);
};

export default ListObserver;
