import Chip from "../../shared/Chip";
import { Button, useDisclosure } from "@chakra-ui/react";
import { BsBroadcastPin } from "react-icons/bs";
// import { useRecoilState } from "recoil";
// import eventState from "../../store/eventState";
import RModal from "./RModal";
import { useTranslation } from "react-i18next";
// import { useLocation } from "react-router-dom";

const UserControls = () => {
	// const [state] = useRecoilState(eventState);
	const { isOpen, onToggle, onClose } = useDisclosure();
	// const location = useLocation();

	// const disabledPopUp = location.pathname !== "/";

	const { t } = useTranslation();

	return (
		<Chip className="flex items-center gap-2 text-lg font-bold capitalize text-white">
			<div className="group flex items-center gap-2 transition-all duration-300">
				<img src="/memoji.png" alt="user" className="h-12 w-12" />
				<p
					className="w-0 overflow-hidden text-dolphin-700 duration-300 group-hover:w-[140px]"
					style={{ whiteSpace: "nowrap" }}
				>
					Eleana Giorgetti
				</p>
			</div>

			<Button
				variant={"primary"}
				className={`${"green-btn"} self-stretch pl-3 pr-5 btn-base !cursor-default`}
				// onClick={() => onToggle()}
				leftIcon={<BsBroadcastPin />}
				// isDisabled={true}
			>
				{t("ONAIR")}
			</Button>
			<RModal isOpen={isOpen} onClose={onClose} onToggle={onToggle} />
		</Chip>
	);
};

export default UserControls;
