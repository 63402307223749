import { useNavigate } from "react-router-dom";
import SlideShowIcon from "../../../icons/SlideShow";
import { Button, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import slideShowStore from "./store";
import resultsStore from "./slideShowResult/store";
import { startContentSharing } from "../../../lib/services/events";
import eventState from "../../../store/eventState";

const SlideShowEnd = () => {
	const navigate = useNavigate();
	const toast = useToast();
	const slides = useRecoilValue(slideShowStore);

	const [, setEventState] = useRecoilState(eventState);
	const [, setResultsStore] = useRecoilState(resultsStore);

	const [loading, setLoading] = useState(false);

	const { t } = useTranslation();

	const handleShowResults = () => {
		setLoading(true);
		startContentSharing(slides.contentId, "show_results")
			.then((data) => {
				setEventState((prev) => ({
					...prev,
					activities: prev.activities.map((item, j) =>
						prev.activeContentIndex === j ? { ...item, state: "played" } : item
					),
				}));
				navigate("../result", { relative: "path" });
				setResultsStore(data.data.data.results);
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className="bg-blue-600 flex-1 m-5 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="stroke-white scale-[2.2]">
				<SlideShowIcon />
			</p>
			<div className="text-white max-w-[820px] text-3xl text-center">
				<h3>{t("Presentation ended!")}</h3>
			</div>
			<Button
				variant={"outline !transition-all !duration-300 font-bold"}
				className="outline-btn w-[400px]"
				size={"md"}
				isDisabled={loading}
				onClick={handleShowResults}
			>
				{t("Display results")}
			</Button>
		</div>
	);
};

export default SlideShowEnd;
