import Chip from "../../shared/Chip";
// import { Button } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import eventState from "../../store/eventState";
import Back from "../../icons/Back";
import { goBack } from "../../lib/services/events";
import { Button, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ActivityControl = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const toast = useToast();

	const [state, setState] = useRecoilState(eventState);
	const [loading, setLoading] = useState(false);

	const exitActivity = () => {
		setLoading(true);
		goBack()
			.then(() => {
				setState((prev) => ({
					...prev,
					activity: undefined,
					live: true
				}));
				navigate("/");
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: "",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<Chip className="flex items-center justify-center self-stretch">
			<Button
				isDisabled={loading}
				className={`p-[15px] rounded transition-all duration-300 ${
					state.live ? "violet-btn !fill-white" : "bg-white !fill-violet-600"
				}`}
				onClick={exitActivity}
			>
				<Back />
			</Button>
		</Chip>
	);
};

export default ActivityControl;
