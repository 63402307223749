import {
	Modal as ModalChakra,
	ModalBody,
	ModalContent,
	ModalOverlay,
} from "@chakra-ui/react";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
	variant: "popup" | "info";
};

const Modal = ({ isOpen, children, variant, onClose }: Props) => {
	return (
		<ModalChakra
			isOpen={isOpen}
			onClose={onClose}
			variant={variant}
			closeOnOverlayClick={false}
			closeOnEsc={false}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalBody>{children}</ModalBody>
			</ModalContent>
		</ModalChakra>
	);
};

export default Modal;
