import Title from "./Title";
import ActivityIndicator from "./ActivityIndicator";
import ParticipantViewer from "./ParticipantViewer";
import ActivityControl from "./ActivityControl";
import UserControls from "./UserControls";
import ToggleFullScreen from "./ToggleFullScreen";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import eventState, { defaultEventState } from "../../store/eventState";

const Header = () => {
	const [, setEState] = useRecoilState(eventState);

	useEffect(() => {
		setEState(defaultEventState);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<header className="sticky left-0 top-0 z-50 flex w-full items-center gap-5 bg-white p-5">
			<UserControls />
			<ParticipantViewer />
			<Title />
			<ActivityIndicator />
			<ActivityControl />
			<ToggleFullScreen />
		</header>
	);
};

export default Header;
