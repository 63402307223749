import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import eventState, { GameType } from "../../../store/eventState";
import { startContentSharing } from "../../../lib/services/events";
import { useState } from "react";
import Game from "../../../icons/Game";

const GameStart = () => {
	const navigate = useNavigate();

	const eventStorage = useRecoilValue(eventState);
	const [loading, setLoading] = useState(false);

	const currentEvent = eventStorage.activities[
		eventStorage.activeContentIndex
	] as GameType;

	const { t } = useTranslation();

	const handleEvent = () => {
		startContentSharing(currentEvent.id, "start")
			.then(() => navigate("wait-submit"))
			.finally(() => setLoading(false));
	};

	return (
		<div className="bg-bile-600 flex-1 m-5 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="scale-[2] fill-white">
				<Game />
			</p>
			<div className="text-white max-w-[820px] text-3xl text-center">
				<h3>{currentEvent.title}</h3>
				<p
					className="font-bold"
					dangerouslySetInnerHTML={{ __html: currentEvent.description }}
				/>
			</div>
			<Button
				variant={"outline"}
				className="disabled:cursor-not-allowed outline-btn w-[400px]  !transition-all !duration-300 font-bold !text-[24px] !leading-[24px]"
				size={"md"}
				isDisabled={!eventStorage.live || loading}
				onClick={() => handleEvent()}
			>
				{t("Open")}
			</Button>
		</div>
	);
};

export default GameStart;