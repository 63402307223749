import { useEffect, useState } from "react";
import SlideShowIcon from "../../../icons/SlideShow";
import { Button, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import slideShowStore from "./store";
// import data from "../../../data/slideshows/data";
import { useTranslation } from "react-i18next";
import eventState, { SlideActivity } from "../../../store/eventState";
import { startContentSharing } from "../../../lib/services/events";

const SlideShowStart = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const [, setSlides] = useRecoilState(slideShowStore);
	const eventStorage = useRecoilValue(eventState);
	const { currentIndex } = useParams();
	const [loading, setLoading] = useState(false);

	const index = +(currentIndex as string);

	const { t } = useTranslation();

	// ! fetch all data here and set it to store
	useEffect(() => {
		const currentContent = eventStorage.activities[index] as SlideActivity;

		setSlides((prev) => ({
			...prev,
			total: currentContent.slides.length,
			slides: currentContent.slides,
			contentId: currentContent.id,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSlideShowStart = () => {
		const currentActivity =
			eventStorage.activities[eventStorage.activeContentIndex];
		setLoading(true);
		startContentSharing(currentActivity.id, "start")
			.then(() => {
				navigate("player");
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className="bg-blue-600 flex-1 m-5 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="stroke-white scale-[2.2]">
				<SlideShowIcon />
			</p>
			<div className="text-white max-w-[820px] text-3xl text-center">
				<h3>{eventStorage.activities[index].title}</h3>
				<p
					className="font-bold"
					dangerouslySetInnerHTML={{
						__html: eventStorage.activities[index].description,
					}}
				/>
			</div>
			<Button
				variant={"outline"}
				className={
					"outline-btn w-[400px] !transition-all !duration-300 font-bold"
				}
				size={"md"}
				isDisabled={!eventStorage.live || loading}
				onClick={handleSlideShowStart}
			>
				{t("Start")}
			</Button>
		</div>
	);
};

export default SlideShowStart;
