const DisLike = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.64475 1.81255H1.47606C1.2459 1.81321 1.02297 1.89296 0.844618 2.03843C0.666264 2.18389 0.543329 2.38624 0.496412 2.61157L0.433911 2.91615C-0.164693 5.8137 -0.14372 8.80509 0.495454 11.694C0.545352 11.9161 0.669119 12.1147 0.846524 12.2574C1.02393 12.4001 1.24448 12.4783 1.47214 12.4794H4.64475C4.90988 12.4791 5.16406 12.3737 5.35153 12.1862C5.539 11.9987 5.64446 11.7445 5.64477 11.4794V2.81257C5.64447 2.54744 5.53902 2.29325 5.35154 2.10578C5.16407 1.9183 4.90988 1.81285 4.64475 1.81255ZM4.31141 11.1461H1.74202C1.198 8.52005 1.19735 5.81045 1.74011 3.18416L1.74794 3.14591H4.31141V11.1461Z"
        fill="white"
      />
      <path
        d="M19.8684 6.5824L16.8995 1.36446C16.8113 1.20977 16.6838 1.08114 16.5299 0.991608C16.3759 0.902073 16.2011 0.854813 16.023 0.854614H10.5396C10.3602 0.854636 10.184 0.902489 10.0293 0.993242L7.02873 2.75345V4.29931L10.6278 2.18798H15.834L18.6669 7.16683V7.55313L17.8461 9.74188H12.5001L11.8335 10.4086V12.4494L11.8925 12.7238L12.3505 13.738C12.5844 14.2562 12.6215 14.8418 12.4551 15.3854C12.2886 15.9289 11.9299 16.3933 11.4459 16.6916L7.99925 9.14604H7.00002V10.1461L10.3754 17.5568C10.4565 17.7327 10.5864 17.8816 10.7496 17.9859C10.9128 18.0902 11.1025 18.1456 11.2962 18.1454C11.4349 18.1454 11.572 18.1166 11.6989 18.0606C12.1428 17.8652 12.5436 17.5837 12.8781 17.2324C13.2127 16.8811 13.4743 16.4671 13.6478 16.0141C13.8214 15.5612 13.9035 15.0783 13.8894 14.5935C13.8753 14.1086 13.7652 13.6313 13.5656 13.1892L13.1668 12.3059V11.0752H18.0711C18.2762 11.0746 18.4763 11.0117 18.6449 10.8949C18.8135 10.7781 18.9426 10.6129 19.0153 10.4211L19.9357 7.9666C19.9783 7.85336 20.0001 7.73338 20 7.61242V7.08099C20 6.90621 19.9546 6.73442 19.8684 6.5824Z"
        fill="white"
      />
    </svg>
  );
};

export default DisLike;
