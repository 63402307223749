import Clock from '../../../icons/Clock'
import { useRecoilValue } from 'recoil';
import eventState, { MakeYourListActivity } from '../../../store/eventState';
import useGameChannel from '../../../hooks/events/useGameChannel';

const WaitSubmit = () => {
	useGameChannel();
	
  const eventStorage = useRecoilValue(eventState);
  const currentEvent = eventStorage.activities[
      eventStorage.activeContentIndex
    ] as MakeYourListActivity;
  
  return (
    <div className="bg-bile-600 flex-1 m-5 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="scale-[3] fill-white">
				<Clock />
			</p>
			<div className="text-white max-w-[820px] text-3xl text-center">
				<h3>{currentEvent.title}</h3>
				<p
					className="font-bold"
					dangerouslySetInnerHTML={{ __html: currentEvent.description }}
				/>
			</div>
		</div>
  )
}

export default WaitSubmit