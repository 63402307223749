type Props = {
	index: number;
	avg: number;
	percentage: number;
	content: string;
	isHighest: boolean;
};

const ListItemResultCard = ({
	avg,
	content,
	index,
	percentage,
	isHighest,
}: Props) => {
	return (
		<div
			className={`flex p-4 items-center gap-4 rounded ${
				isHighest ? "bg-violet-600" : "bg-white"
			}`}
		>
			<div className="h-8 w-8 rounded bg-violet-100 text-violet-600 flex items-center justify-center font-bold text-lg leading-[21.6px]">
				{index}
			</div>
			<p
				className={`${
					isHighest ? "text-white" : "text-dolphin-700"
				} text-base leading-5 flex-1`}
			>
				{content}
			</p>
			<div className="flex items-center gap-2">
				{/* <div className="!w-[205px] px-2 py-[6px] flex items-center gap-[10px] bg-violet-200 rounded-[2px]"> */}
				{/* <div className="rounded !w-[119px] bg-violet-300 flex-1 h-3 overflow-hidden transition-all duration-300">
						<span
							style={{ width: `${percentage}%` }}
							className="h-3 bg-violet-600 block transition-all duration-200"
						/>
					</div> */}
				{/* <p className="text-violet-600 text-base leading-5 w-[60px] text-right">
						{percentage}%
					</p> */}
				{/* </div> */}
				<div className="h-8 px-1 rounded bg-violet-100 text-violet-600 flex items-center justify-center font-bold text-lg leading-[21.6px]">
					{(+avg || 0).toFixed(1)}
				</div>
			</div>
		</div>
	);
};

export default ListItemResultCard;
