import Digit from "./Digit";
// credits to buildui.com

const fontSize = 30;

const Counter = ({ value }: { value: number }) => {
  return (
    <div
      style={{ fontSize }}
      className="flex space-x-1 overflow-hidden rounded px-2 leading-none "
    >
      <Digit place={100} value={value} />
      <Digit place={10} value={value} />
      <Digit place={1} value={value} />
    </div>
  );
}

export default Counter;