/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import pusherInstance from "../../lib/clients/pusherInstance";
import { useRecoilState } from "recoil";
import userSelection from "../../pages/activities/buzzer/BuzzerControl/store";

type scoreMsg = {
	data: {
		result: boolean;
		option: string;
		question: string;
	};
};

const useBuzzerChannel = () => {
	const [, setSelection] = useRecoilState(userSelection);

	const channel_name = "private-presenter";

	useEffect(() => {
		const pusher = pusherInstance();

		const buzzerChannel = pusher.subscribe(channel_name);

		// buzzerChannel.bind("buzzer", (data: MsgType) => {
		// 	setSelection((prev) => ({
		// 		...prev,
		// 		selectionState: data.data.result,
		// 	}));
		// });

		buzzerChannel.bind("user-submit", (data: scoreMsg) => {
			setSelection((prev) => ({
				...prev,
				userSelecting: false,
				selectionState: data.data.result,
				userSelected: +data.data.option
			}))
		})
	}, []);
};

export default useBuzzerChannel;
