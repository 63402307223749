import Buzzer from "../icons/Buzzer";
import Game from "../icons/Game";
import List from "../icons/List";
import Quiz from "../icons/Quiz";
import SlideShow from "../icons/SlideShow";
import Survey from "../icons/Survey";

const ExerciseTypes = {
  slideShow: {
    icon: SlideShow,
    color: "stroke-blue-600",
    bg: "!bg-blue-600 stroke-white",
    name: "SLIDESHOW",
    link: "slideshow",
    type: 1,
  },
  quiz: {
    icon: Quiz,
    color: "fill-blaze-600",
    bg: "!bg-blaze-600 !fill-white",
    name: "TRUE/FALSE",
    link: "quiz",
    type: 2,
  },
  survey: {
    icon: Survey,
    color: "stroke-denim-600",
    bg: "!bg-denim-600 !stroke-white",
    name: "SURVEY",
    link: "survey",
    type: 3,
  },
  buzzer: {
    icon: Buzzer,
    color: "stroke-pink-600",
    bg: "!bg-pink-600 stroke-white",
    name: "BUZZER",
    link: "buzzer",
    type: 4,
  },
  list: {
    icon: List,
    color: "fill-red-600",
    bg: "!bg-red-600 !fill-white",
    name: "MAKE YOUR LIST",
    link: "list",
    type: 5,
  },
  game: {
    icon: Game,
    color: "fill-bile-600",
    bg: "!bg-bile-600 fill-white",
    name: "GAME",
    link: "game",
    type: 6,
  },
};

export default ExerciseTypes;
