import {
	CardBody,
	Card as CardChakra,
	CardFooter,
	CardHeader,
} from "@chakra-ui/card";
import { ReactNode } from "react";
import Chip from "../../shared/Chip";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
// import { BiReset } from "react-icons/bi";

interface Props {
	title: string;
	type: string;
	icon: ReactNode;
	details: string;
	num: number;
	color: string;
	display: "grid" | "list";
	onClick: () => void;
	isDisabled: boolean;
	openResetModal: () => void;
}

const Card = ({
	details,
	icon,
	num,
	title,
	type,
	color,
	display,
	onClick,
	isDisabled,
	openResetModal,
}: Props) => {
	const { t } = useTranslation();

	if (display === "grid") {
		return (
			<CardChakra className="group rounded border-[1px] border-solid border-violet-200 bg-violet-100 p-5 hover:bg-violet-200">
				<CardHeader className="item-center flex justify-between">
					<div className="flex items-center justify-center">
						<Chip
							className={`flex h-fit items-center !p-0 text-white ${color}`}
						>
							<div
								className={`flex h-9 w-9 items-center justify-center p-[10px]`}
							>
								{icon}
							</div>
							<h4 className="flex items-center pr-5 text-[15px] leading-relaxed">
								{type}
							</h4>
						</Chip>
					</div>
					<div className="flex h-9 w-9 items-center justify-center rounded bg-violet-300 font-bold text-white group-hover:bg-violet-400">
						{num}
					</div>
				</CardHeader>
				<CardBody className="mb-[24px] mt-[10px] flex flex-col gap-[10px] flex-1">
					<h3 className="text-bold text-2xl font-bold text-dolphin-700">
						{title}
					</h3>
					<p
						className="text-dark-pure text-[15px]"
						dangerouslySetInnerHTML={{ __html: details }}
					/>
				</CardBody>
				<CardFooter className="flex justify-between">
					{/* <Button
						onClick={openResetModal}
						className="text-18px flex items-center gap-[5px] rounded-[60px] border-[1px] border-white bg-[#ffffff90] px-4 py-2 font-bold text-red-600 transition-all duration-300 hover:bg-red-500 hover:text-red-100 hover:border-red-100"
						isDisabled={isDisabled}
					>
						<BiReset />
					</Button> */}
					<div />
					<Button
						onClick={onClick}
						className="text-lg flex items-center gap-[5px] border-[1px] border-white bg-[#ffffff90] px-4 py-2 font-bold text-violet-600 transition-all duration-300 group-hover:border-violet-600 group-hover:bg-violet-600 group-hover:text-white !self-end"
						isDisabled={isDisabled}
					>
						{t("Start Sharing")}
					</Button>
				</CardFooter>
			</CardChakra>
		);
	}

	return (
		<div className="group flex items-center justify-between rounded border-[1px] border-solid border-violet-200 bg-violet-100 p-5 hover:bg-violet-200">
			<div className="flex items-start flex-col gap-[10px] text-dolphin-700 ">
				<div className="flex items-center rounded-[15px] overflow-hidden">
					<p className="text-[12px] font-bold px-[10px] py-[5px] bg-white leading-[14px]">
						{num}
					</p>
					<p className="text-[11px] px-[10px] py-[5px] bg-[#FFFFFF80] tracking-[-3%] leading-[14px]">
						{type}
					</p>
				</div>
				<div className="text-[24px] font-bold leading-[28px]">{title}</div>
				<p className="text-sm" dangerouslySetInnerHTML={{ __html: details }} />
			</div>
			<div>
				<Button
					onClick={onClick}
					className="text-18px flex items-center gap-[5px] rounded-[60px] border-[1px] border-white bg-[#ffffff90] px-4 py-2 font-bold text-violet-600 transition-all duration-300 group-hover:border-violet-600 group-hover:bg-violet-600 group-hover:text-white"
				>
					{t("Start sharing")}
				</Button>
			</div>
		</div>
	);
};

export default Card;
