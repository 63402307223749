/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import pusherInstance from "../../lib/clients/pusherInstance";
import { useNavigate } from "react-router-dom";

type scoreMsg = {
	data: {
		overall_progress: string;
		score: {
			text: string;
			percentage: string;
		}[];
	};
};

const useGameChannel = () => {
	const navigate = useNavigate();

	const channel_name = "private-presenter";

	useEffect(() => {
		const pusher = pusherInstance();

		const slideShowChannel = pusher.subscribe(channel_name);

		slideShowChannel.bind("html-game", (data: scoreMsg) => {
			// console.log(data.data.overall_progress, data.data.score);
			if (data.data.overall_progress === "100.00") {
				navigate("../show-result", { replace: false });
			}
		});
	}, []);
};

export default useGameChannel;
