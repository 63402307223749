import axios from "../clients/axiosInstance";
import END_POINT from "../api/END_POINTS";

const { V1 } = END_POINT;

export const joinEvent = (firstName: string, lastName: string) => {
	return axios.post(V1.JOIN_EVENT, {
		last_name: lastName,
		first_name: firstName,
		event: 1,
	});
};

export const getAvatars = () => {
	return axios.get(V1.GET_AVATARS);
};

export const setAvatar = (avatar: string, user_id: string) => {
	return axios.post(V1.SET_AVATAR, { user_id, avatar });
};

export const getAllUsers = () => {
	return axios.get(V1.GET_ALL_USERS);
};

export const kickUsers = (id: string | number) => {
	return axios.post(V1.KICK_USER, {
		event_id: 1,
		user_id: id,
	});
};

export const userStatus = (id: string | number, state: boolean) => {
	return axios.post(V1.POST_USER_STATUS(id), { online: state ? 1 : 0 });
};
