import { atom } from "recoil";

const resultCarousel = atom<{ currentIndex: number; total: number }>({
  key: "resultCarousel",
  default: {
    currentIndex: 0,
    total: 0,
  },
});

export default resultCarousel;
