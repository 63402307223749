import { useTranslation } from "react-i18next";
import Chip from "../../../shared/Chip";
import cStyle from "./module/slideshow.module.css";

type Props = {
	state: "USED" | "ONLINE_NOW" | "NEXT" | "NOT_USED";
	title: string;
	onClick: () => void;
	loading: boolean;
};

const CarouselCard = ({ state, title, onClick, loading }: Props) => {
	const { t } = useTranslation();

	const cardStyle =
		state === "USED"
			? cStyle.used
			: state === "ONLINE_NOW"
			  ? cStyle.live
			  : state === "NEXT"
			    ? cStyle.next
			    : cStyle.notUsed;

	return (
		<Chip
			className={`${
				loading ? "cursor-not-allowed" : "cursor-pointer"
			} flex flex-col gap-5 !p-4 rounded self-stretch min-w-[256px] max-w-[256px] w-[256px] transition-all duration-300 ${cardStyle}`}
			onClick={onClick}
		>
			<Chip
				className={`${cStyle.child} px-4 py-[6px] rounded text-[12px] font-bold !w-fit`}
			>
				{t(state.split("_").join(" "))}
			</Chip>
			<p className={`${cStyle.title} text-lg font-bold line-clamp-2`}>{title}</p>
		</Chip>
	);
};

export default CarouselCard;
