const Like = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.61229 7.26553H1.50228C1.26668 7.26442 1.03784 7.34422 0.854012 7.49159C0.670187 7.63895 0.542519 7.84496 0.492352 8.07515C-0.142844 10.9059 -0.16367 13.84 0.431278 16.6794L0.493768 16.9789C0.542934 17.2114 0.670316 17.4201 0.854673 17.57C1.03903 17.72 1.26922 17.8022 1.50686 17.803H4.6125C4.88683 17.8027 5.14984 17.6935 5.34382 17.4995C5.5378 17.3056 5.64691 17.0426 5.64722 16.7682V8.30024C5.64691 8.02588 5.53776 7.76284 5.34374 7.56886C5.14972 7.37487 4.88666 7.26578 4.61229 7.26553ZM4.31388 16.4698H1.74932L1.73645 16.4079C1.1967 13.8326 1.19894 11.1731 1.74303 8.59866H4.31388V16.4698Z"
        fill="white"
      />
      <path
        d="M19.9358 10.3739L19.0155 7.91985C18.9429 7.72803 18.8138 7.56277 18.6452 7.44593C18.4766 7.3291 18.2765 7.26619 18.0714 7.26553H13.1677V6.0351L13.5665 5.1519C13.7661 4.70989 13.8762 4.23271 13.8903 3.74793C13.9044 3.26314 13.8223 2.78037 13.6488 2.32749C13.4752 1.87461 13.2137 1.46061 12.8792 1.10941C12.5447 0.758205 12.144 0.476758 11.7001 0.281328C11.4574 0.175641 11.183 0.169222 10.9356 0.263447C10.6882 0.357673 10.4876 0.545047 10.3767 0.785417L7.03089 8.10978V9.15391H8.01953L11.4472 1.65016C11.931 1.94843 12.2897 2.41269 12.4562 2.95617C12.6226 3.49965 12.5855 4.08516 12.3516 4.60324L11.8938 5.61729L11.8346 5.89158V7.9321L12.5012 8.59866H17.8462L18.6669 10.787V11.1733L15.8345 16.1512H10.6294L7.03089 14.0403V15.5859L10.0307 17.3457C10.1855 17.4364 10.3616 17.4843 10.5411 17.4844H16.0235C16.2016 17.4842 16.3764 17.4369 16.5303 17.3474C16.6842 17.2579 16.8117 17.1293 16.8999 16.9747L19.8681 11.7577C19.9545 11.6057 19.9999 11.4339 20 11.259V10.728C20.0001 10.607 19.9783 10.4871 19.9358 10.3739Z"
        fill="white"
      />
    </svg>
  );
};

export default Like;
