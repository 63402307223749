import { atom } from "recoil";
import { LiveUsers } from "../../../../../store/userLiveStore";

type TUserSelection = {
  userSelecting: boolean;
  user: LiveUsers | undefined;
  selectionState: boolean;
  adminSelecting: boolean,
  userSelected: number;
}

const userSelection = atom<TUserSelection>({
  key: "buzzerUserSelection",
  default: {
    userSelecting: true,
    user: undefined,
    selectionState: false,
    adminSelecting: true,
    userSelected: -1,
  }
})

export default userSelection;