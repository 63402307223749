import Chip from "../../../shared/Chip";

type Props = {
	statement: string;
	index: number;
	isRight?: boolean;
	withAnswer?: boolean;
	percent?: number;
};

const ListItem = ({
	statement,
	isRight,
	withAnswer = false,
	percent,
}: Props) => {
	return (
		<Chip
			className={`!p-4 rounded flex items-center border-solid gap-5 ${
				isRight
					? "!bg-violet-600 border-violet-600"
					: "!bg-white border-violet-300"
			} `}
		>
			<div className="flex flex-col gap-5 flex-1">
				<p className={`text-2xl leading-[28px] ${isRight ? "text-white" : "text-dolphin-700"}`}>
					{statement}
				</p>
			</div>
			{withAnswer && (
				<div className="!w-[340px] px-5 py-[13px] flex items-center gap-[10px] bg-violet-200 rounded-[60px] border-[1px]">
					<div className="rounded !w-[230px] bg-violet-300 flex-1 h-[18px] overflow-hidden transition-all duration-300">
						<span
							style={{ width: `${percent}%`}}
							className="h-[18px] bg-violet-600 block transition-all duration-200"
						/>
					</div>
					<p className="text-violet-600 text-[24px] leading-[28px] w-[60px] text-right">
						{percent}%
					</p>
				</div>
			)}
		</Chip>
	);
};

export default ListItem;
