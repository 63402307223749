import axios from "../clients/axiosInstance";
import END_POINT from "../api/END_POINTS";

export type ResponseType = {
	data: {
		id: number;
		title: string;
		description: string;
		type: 3;
		type_name: "Survey";
		image: null;
		random: null;
		overall_progress: string;
		questions: [
			{
				id: number;
				text: string;
				description: string | null;
				type: 1 | 2;
				type_name: "Single" | "Multiple";
				options: {
					id: number;
					text: string;
					option_percentage: string;
					option_progress: string;
				}[];
			},
		];
	};
};

const { V1 } = END_POINT;

export const getSurveyResults = (id: string | number) => {
	return axios.get(V1.GET_SURVEY_RESULTS(id));
};
