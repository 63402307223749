import { FaLock } from "react-icons/fa";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";

const UnAuthorized = () => {
	const { t } = useTranslation();

	return (
		<div className="text-center fix h-screen flex items-center justify-center flex-col">
			<div>
				<FaLock className="text-4xl mx-auto" />
			</div>
			<div className={styles.message}>
				<h1>{t("Access to this page is restricted")}</h1>
				<p>
					{t(
						"Please check with the site admin if you believe this is a mistake."
					)}
				</p>
			</div>
		</div>
	);
};

export default UnAuthorized;
