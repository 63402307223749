import Chip from "../../../../shared/Chip";
import ResultCard from "./ResultCard";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import resultsStore from "./store";

const SlideShowResults = () => {

	const data = useRecoilValue(resultsStore);
	
	const { t } = useTranslation();
	
	return (
		<div className="flex-1 p-5 flex items-center justify-center">
			<Chip className="flex-1 self-stretch bg-violet-100 rounded p-5">
				<h3 className="text-[30px] font-bold text-dolphin-700 mb-[30px]">
					{t("Top liked and disliked slides")}
				</h3>
				<div className="grid grid-cols-12 gap-5">
					{/* top liked */}
					<div className="flex flex-col gap-5 col-span-6">
						{data.liked.map((result, i) => (
							<ResultCard
								key={i}
								count={result.total}
								imgUrl={result.slide_image}
								liked={true}
								title={result.title}
							/>
						))}
					</div>
					{/* top disliked */}
					<div className="flex flex-col gap-5 col-span-6">
					{data.disliked.map((result, i) => (
							<ResultCard
								key={i}
								count={result.total}
								imgUrl={result.slide_image}
								liked={false}
								title={result.title}
							/>
						))}
					</div>
				</div>
			</Chip>
		</div>
	);
};

export default SlideShowResults;
