import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
);

const login = definePartsStyle({
  field: {
    fontFamily: "Inter",
    fontSize: "20px",
    color: "black",
    py: "20px",
    textAlign: "center",
    _placeholder: {
      color: "black",
    },
    outline: "none",
    borderRadius: 5,
  },
});

export const inputTheme = defineMultiStyleConfig({
  variants: {
    login,
  },
});
