import { Button, useToast } from "@chakra-ui/react";
import eventState from "../../store/eventState";
import Modal from "../../components/Modal";
import Antenna from "../../icons/Antenna";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { goOffline, goOnline } from "../../lib/services/events";
import { useMutation } from "@tanstack/react-query";

type Props = {
	isOpen: boolean;
	onToggle: () => void;
	onClose: () => void;
};

const RModal = ({ isOpen, onClose, onToggle }: Props) => {
	const toast = useToast();
	
	const [state, setState] = useRecoilState(eventState);
	const location = useLocation();

	const { mutate, isPending } = useMutation({
		mutationFn: () =>
			state.live ? goOffline() : goOnline(),
		onSuccess: (data) => {
			setState(prev => ({
				...prev,
				live: true
			}))
			onToggle();
		},
		onError: () => {
			toast({
				title: t("Something went wrong") + ".",
				description: "",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	});

	const { t } = useTranslation();

	const handleLiveChanges = () => {
		if (location.pathname === "/") {
			mutate();
		}
	};

	return (
		<Modal isOpen={isOpen} variant="popup" onClose={onClose}>
			<div className="flex flex-col h-full justify-between">
				<div className="flex items-center flex-col gap-5">
					<span className="py-[25px] ">
						<Antenna />
					</span>
					<p className="text-2xl font-bold leading-7 text-dolphin-700 text-center">
						{state.live
							? t("Are you sure want to stop sharing?")
							: t("You’re going to start sharing")}
					</p>
				</div>
				<div className="flex flex-col items-center gap-10">
					<Button
						className={`${
							state.live ? "orange-btn" : "pink-btn"
						} btn-base w-full !py-4 font-bold`}
						size={"md"}
						onClick={handleLiveChanges}
						isDisabled={isPending}
					>
						{state.live ? t("Stop") : t("Start Sharing")}
					</Button>
					<Button isDisabled={isPending} onClick={onToggle}>{t("Cancel Request")}</Button>
				</div>
			</div>
		</Modal>
	);
};

export default RModal;
