import { useRecoilState } from "recoil";
import Card from "./Card";
import HomeControls from "../../components/HomeControls";
import { mapExerciseToValue } from "../../utils/mapExerciseToValue";
import { FaQuestion, FaSpinner } from "react-icons/fa";
import displayType from "../../store/displayView";
import eventState, {
	activityType,
	defaultEventState,
} from "../../store/eventState";
import { useEffect, useState } from "react";
import NotLiveModal from "./NotLiveModal";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
// bg-blue-600 bg-pink-600 bg-bile-600 bg-blaze-600 bg-pink-600
import { useQuery, useMutation } from "@tanstack/react-query";
import {
	contentStandby,
	deleteResults,
	getEvents,
	goOffline,
	goOnline,
} from "../../lib/services/events";
import { useTranslation } from "react-i18next";
import ResetContentModal from "./ResetContentModal";

const Home = () => {
	const toast = useToast();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [display] = useRecoilState(displayType);
	const [eState, setEventState] = useRecoilState(eventState);
	const [link, setLink] = useState("");
	const [disableButtons, setDisableButtons] = useState(false);

	const [selectedToReset, setSelectedToReset] = useState<string | number>(0);

	const { isOpen, onToggle, onClose } = useDisclosure();
	const {
		isOpen: isOpenReset,
		onToggle: onToggleReset,
		onClose: onCloseReset,
	} = useDisclosure();

	const { data, isFetching, refetch } = useQuery({
		queryKey: ["events"],
		refetchOnMount: "always",

		queryFn: () => getEvents(),
	});

	const { mutateAsync } = useMutation({
		mutationFn: (id: number) => contentStandby(id),
	});

	const { mutateAsync: mutateReset } = useMutation({
		mutationFn: (id: number | string) => deleteResults(id),
	});

	const { mutate, isPending } = useMutation({
		mutationFn: () => (eState.live ? goOffline() : goOnline()),
		onSuccess: (data) => {
			const activityInfo = eState.activities[eState.activeContentIndex];
			onToggle();
			setTimeout(() => {
				handleStartSharing(activityInfo.id, link);
			}, 400);
			setEventState((prev) => ({
				...prev,
				live: data.data.data.is_live,
			}));
		},
		onError: () => {
			toast({
				title: t("Something went wrong") + ".",
				description: "",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		},
	});

	const handleResetContent = () => {
		setDisableButtons(true);
		mutateReset(selectedToReset)
			.then(() => {
				refetch();
				onCloseReset();
				toast({
					title: t("Content Resat Successfully") + ".",
					description: "",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: "",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => {
				setDisableButtons(false);
				setSelectedToReset(-1);
			});
	};

	const handleStartSharing = (id: number, navigationLink: string) => {
		setDisableButtons(true);
		mutateAsync(id)
			.then((_) => {
				navigate(navigationLink);
			})
			.catch((_) => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => setDisableButtons(false));
	};

	useEffect(() => {
		if (!isFetching) {
			const { data: responseData } = data?.data;
			setEventState((prev) => ({
				...defaultEventState,
				eventTitle: responseData.title,
				activities: responseData.contents.map((item: any) => ({
					...item,
				})),
				live: prev.live,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching]);

	return (
		<>
			<HomeControls />
			<div className="p-5">
				<div
					className={
						display === "grid"
							? "grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
							: "flex flex-col gap-5"
					}
				>
					{isFetching ? (
						<div className="h-[calc(100vh-106px-100px)] flex items-center justify-center col-span-12">
							<FaSpinner className="animate-spin text-2xl" />
						</div>
					) : (
						eState.activities.map((exc: activityType, i: number) => {
							const mappedType = mapExerciseToValue(exc.type) || {
								icon: FaQuestion,
								color: "stroke-red-600",
								bg: "bg-red-600 text-white",
								name: "UNKNOWN",
								link: "",
							};

							return eState.currentState === exc.state ||
								eState.currentState === "allContents" ? (
								<Card
									details={exc.description}
									icon={<mappedType.icon />}
									color={mappedType.bg}
									num={i + 1}
									title={exc.title}
									type={mappedType.name}
									key={exc.id}
									display={display}
									isDisabled={disableButtons}
									onClick={() => {
										setEventState((prev) => ({
											...prev,
											activity: {
												activityName: exc.title,
												activityType: exc.type,
											},
											activityTitle: exc.title,
											activeContentIndex: i,
										}));
										setLink(`/activity/${i}/${mappedType.link}`);
										// if (!eState.live) {
										// 	onToggle();
										// } else {
										// navigate(`/activity/${i}/${mappedType.link}`);
										handleStartSharing(
											exc.id,
											`/activity/${i}/${mappedType.link}`
										);
										// }
									}}
									openResetModal={() => {
										setSelectedToReset(exc.id);
										onToggleReset();
									}}
								/>
							) : (
								<></>
							);
						})
					)}
				</div>
			</div>
			<NotLiveModal
				isOpen={isOpen}
				onClose={onClose}
				onToggle={onToggle}
				loading={isPending}
				onAccept={() => {
					mutate();
				}}
				onCancel={() => {
					navigate(link);
				}}
			/>
			<ResetContentModal
				isOpen={isOpenReset}
				onClose={onCloseReset}
				handleMutate={handleResetContent}
				loading={disableButtons}
			/>
		</>
	);
};

export default Home;
