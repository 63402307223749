import { Button, useToast } from "@chakra-ui/react";
import Chip from "../../../shared/Chip";
import ListItem from "./ListItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import eventState, { MakeYourListActivity } from "../../../store/eventState";
import { startContentSharing } from "../../../lib/services/events";
import { useState } from "react";

const StartListActivity = () => {
	const navigate = useNavigate();
	const toast = useToast();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	const eventStorage = useRecoilValue(eventState);

	const currentEvent = eventStorage.activities[
		eventStorage.activeContentIndex
	] as MakeYourListActivity;

	const handleStartContentShare = () => {
		setLoading(true);
		startContentSharing(currentEvent.id, "start")
			.then(() => {
				navigate("../observer", { relative: "path" });
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className="flex-1 p-5">
			<Chip className="bg-violet-100 !p-5 rounded flex flex-col gap-5 h-[calc(100vh-106px-60px-68px)]">
				<div className="text-dolphin-700">
					<h3 className="text-[24px] font-bold">{currentEvent.title}</h3>
					<p
						className="text-lg mt-[10px]"
						dangerouslySetInnerHTML={{ __html: currentEvent.description }}
					/>
				</div>
				<div className="flex-1 flex flex-col gap-5 overflow-y-auto custom-scroll">
					{currentEvent.options.map((item) => (
						<ListItem index={item.id} statement={item.text} />
					))}
				</div>
			</Chip>
			<div className="flex justify-end mt-5">
				<Button
					variant={"primary"}
					size="md"
					className="violet-btn btn-base !px-[60px] !text-2xl !leading-[28px]"
					isDisabled={loading}
					onClick={handleStartContentShare}
				>
					{t("Start")}
				</Button>
			</div>
		</div>
	);
};

export default StartListActivity;
