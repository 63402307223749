import { extendBaseTheme } from "@chakra-ui/react";
import { buttonTheme } from "./button";
import { inputTheme } from "./input";
import { theme as th } from "@chakra-ui/theme";
import { info, popup } from "./modal";

const { Modal, Alert, Menu } = th.components;

const theme = extendBaseTheme({
  components: {
    Button: buttonTheme,
    Input: inputTheme,
    Modal: {
      ...Modal,
      variants: {
        ...Modal.variants,
        popup,
        info,
      },
    },
    Alert,
    Menu,
  },
});

export default theme;
