/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import { useNavigate, useRouteError } from "react-router-dom";

const ErrorElement = () => {
	const error = useRouteError();
	const navigate = useNavigate();

	useEffect(() => {
		console.log(error);
		navigate("/");
	}, []);

	return (
		<div className="w-full h-full flex items-center justify-center">
			<FaSpinner className="text-3xl text-dolphin-600 animate-spin" />
		</div>
	);
};

export default ErrorElement;
