import { atom } from "recoil";

type QuizStoreType = {
	overall_progress: string;
	data: {
		text: string;
		description: string;
		results: {
			true: number;
			false: number;
			true_count: number;
			false_count: number;
			isTrue: boolean;
		};
	}[];
};

export const defaultQuiz: QuizStoreType = {
	data: [],
	overall_progress: "",
};

const quizStore = atom<QuizStoreType>({
	key: "quizStore",
	default: defaultQuiz,
});

export default quizStore;
