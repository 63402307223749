import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import slideShowStore from "./store";
import { useRecoilValue } from "recoil";
import "swiper/css";
import { useEffect, useRef } from "react";

const ImageCarousel = () => {
	const slides = useRecoilValue(slideShowStore);

	const swiperRef = useRef<SwiperClass | null>(null);

	useEffect(() => {
		if (swiperRef.current) {
      swiperRef?.current?.slideTo(slides.currentIndex);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slides.currentIndex]);

	return (
		<Swiper
			onSwiper={(swiper: SwiperClass) => {
				swiperRef.current = swiper;
			}}
			spaceBetween={50}
			slidesPerView={1}
			autoplay={false}
			draggable={false}
      allowTouchMove={false}
		>
			{slides.slides.map((slide) => {
				return (
					<SwiperSlide
						key={slide.id}
            draggable={false}
					>
						<img
							src={`${slide.slide_image}`}
							alt={slide.title}
							className="pointer-events-none rounded h-[calc(100vh-106px-150px-70px)] object-contain"
						/>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

export default ImageCarousel;
